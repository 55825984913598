import { createSlice } from '@reduxjs/toolkit'
import { addTransaction, clearTransaction, errorTransaction, finalizeTransaction } from './actions'

export enum TransactionStatus {
  pending = 'pending',
  success = 'success',
  error = 'error',
}
export interface TransactionDetails {
  message: string
  status: TransactionStatus
}

export interface TransactionState {
  [callData: string]: TransactionDetails
}

export const initialState: TransactionState = {}

export const transactionsSlicer = createSlice({
  name: 'transactions',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addTransaction, (transactions, action) => {
        if (transactions[action.payload.callData]) {
          console.log('throw error ne')
          throw Error('Attempted to add existing transaction.')
        }
        transactions[action.payload.callData] = {
          status: TransactionStatus.pending,
          message: '',
        }
      })
      .addCase(clearTransaction, (transactions, { payload: { callData } }) => {
        if (!transactions[callData]) return
        transactions[callData] = null
      })
      .addCase(finalizeTransaction, (transactions, { payload: { callData, message } }) => {
        if (!transactions[callData]) {
          return
        }
        transactions[callData].status = TransactionStatus.success
        transactions[callData].message = message
      })
      .addCase(errorTransaction, (transactions, { payload: { callData, message } }) => {
        if (!transactions[callData]) return
        transactions[callData].status = TransactionStatus.error
        transactions[callData].message = message
      })
  },
})

export default transactionsSlicer.reducer
