import React from "react";
import styled from "styled-components";
import { addCommas, maxNumberAfterDot } from "utils/formatNumber";
import Social from "../../../components/Social";

function Banner({ tvl, totalDptStaked, averageApy, totalDptBnbStaked }) {
  return (
    <BannerStyled>
      <ContentStyled>
        <LeftContent>
          <h1>DIVINER LAUNCHPAD</h1>
          <p>
            The Launchpad on Diviner Protocol is dedicated to facilitate the
            development of gamefi and mateverse landscape. Startups can submit
            project information to apply for IGO (Initial Game Offering).
          </p>

          <Social />
        </LeftContent>

        <RightContent>
          <div className="content">
            <div className="item">
              <span>TVL</span>
              <h3>&#36;{addCommas(maxNumberAfterDot(tvl, 0))}</h3>
            </div>

            <div className="item">
              <span>Average APY</span>
              <h3>
                {averageApy !== "NaN"
                  ? addCommas(maxNumberAfterDot(averageApy, 2))
                  : "0"}
                &#37;
                {/* {averageApy !== 'NaN' ? addCommas(maxNumberAfterDot(averageApy, 2)) : '0'}&#37; */}
              </h3>
            </div>

            <div className="item">
              <span>Total DPT Staked</span>
              <h3>{addCommas(totalDptStaked)}</h3>
            </div>

            <div className="item">
              <span>Total LP Staked</span>
              <h3>{addCommas(totalDptBnbStaked)}</h3>
            </div>
          </div>
        </RightContent>

        <CharactorStyled>
          <img src="./images/homepage/charactor-dpt.png" alt="" />
        </CharactorStyled>

        <CoinStyled>
          <img src="./images/homepage/icon-coin2.png" alt="" />
        </CoinStyled>
      </ContentStyled>
    </BannerStyled>
  );
}

const BannerStyled = styled.div`
  padding-top: 16rem;
  background: linear-gradient(
    113.97deg,
    #351dc9 18.71%,
    #315eff 56.5%,
    #49c8ff 92%
  );
  background-size: cover;
  background-position: 100%;
  width: 100vw;
  height: 100vh;
  position: relative;
  margin-left: -50vw;
  margin-right: -50vw;
  left: 50%;
  right: 50%;
  display: flex;
  align-items: center;
  overflow: hidden;

  @media (max-width: 767px) {
    padding-top: 0;
    height: 100%;
    overflow: visible;
  }
`;

const CharactorStyled = styled.div`
  position: relative;

  img {
    width: 100%;

    @media (max-width: 767px) {
      position: absolute;
      bottom: -80px;
      z-index: 99;
      max-width: 280px;
    }
  }
`;

const CoinStyled = styled.div`
  position: relative;

  img {
    position: absolute;
    bottom: 0;
    right: 100px;

    @media (max-width: 767px) {
      display: none;
    }
  }
`;

const ContentStyled = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 1400px;
  padding-left: 16px;
  padding-right: 16px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 50px;

  ${({ theme }) => theme.mediaQueries.sm} {
    padding-left: 24px;
    padding-right: 24px;
    grid-template-columns: 1fr 605px;
  }

  @media (max-width: 1920px) {
    transform: scale(0.9);
    margin-bottom: 50px;
  }
`;

const LeftContent = styled.div`
  h1 {
    padding-top: 130px;
    font-size: 2rem;
    line-height: 40px;
    font-weight: 600;
    color: #ffffff;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: 16px;

    @media (min-width: 767px) {
      font-size: 3.13rem;
      line-height: 60px;
      padding-top: unset;
    }
  }

  p {
    font-size: 1rem;
    color: #a6d4ff;
    font-weight: 400;
    margin-bottom: 22px;

    @media (min-width: 767px) {
      font-size: 1.3rem;
    }
  }

  .box__social {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
  }
`;

const RightContent = styled.div`
  position: relative;

  &:before {
    content: "";
    background: url("../images/homepage/icon-coin.png") no-repeat;
    background-size: contain;
    background-position: center;
    position: absolute;
    top: -68px;
    left: 38px;
    width: 100%;
    height: 103px;
    z-index: 5;

    @media (min-width: 767px) {
      top: -134px;
      left: 85px;
      width: 360px;
      height: 175px;
    }
  }

  &:after {
    content: "";
    background: url("../images/homepage/icon-coin1.png") no-repeat;
    background-size: contain;
    background-position: center;
    position: absolute;
    top: 100px;
    right: -147px;
    width: 247px;
    height: 214px;
    z-index: 1;
  }

  .content {
    position: relative;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
    background: linear-gradient(147.04deg, #f8af41 17.64%, #f16c42 80.33%);
    border: 1px solid rgba(255, 255, 255, 0.49);
    box-sizing: border-box;
    border-radius: 24px;
    padding: 30px 28px;
    z-index: 4;

    @media (min-width: 767px) {
      padding: 50px 28px;
    }
  }

  span {
    color: #ffffff;
    font-size: 1.2rem;
    white-space: normal;

    @media (min-width: 767px) {
      font-size: 1.7rem;
      white-space: nowrap;
    }
  }

  h3 {
    color: #f5ff7e;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-size: 1.6rem;

    @media (min-width: 767px) {
      font-size: 2.5rem;
    }
  }
`;
export default Banner;
