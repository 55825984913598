import React from 'react'
import styled from 'styled-components'
import Social from './Social'

function Footer() {
  return (
    <FooterStyled>
      <a href="/">
        <img src="/logo.png" className="logo-footer" alt="logo" />
      </a>

      <div className="footer-copyright">
        <span>@2022 DivinerProtocol - v.1.0.8 </span>
        <Social />
      </div>
    </FooterStyled>
  );
}

const FooterStyled = styled.div`
  padding: 0 60px;
  margin-bottom: 30px;

  a {
    @media (max-width: 767px) {
      text-align: center;
      display: block;
    }

    .logo-footer {
      max-width: 161px;
      margin-bottom: 28px;
    }
  }

  .footer-copyright {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding-top: 26px;
    border-top: 2px solid #7eafff;

    @media (max-width: 767px) {
      justify-content: center;
    }

    span {
      font-size: 1rem;
      font-weight: 400;
      color: #ffffff;
      margin-bottom: 10px;

      @media (min-width: 767px) {
        font-size: 1.25rem;
        margin-bottom: 0;
      }
    }

    div {
      margin-bottom: 0;
    }
  }
`;

export default Footer;
