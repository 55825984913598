import { gql } from "@apollo/client";
export const QUERY_USER_HISTORY = gql`
  query user($id: String, $pid: String) {
    users(where: { id: $id }) {
      id
      history(where: { poolId: $pid }) {
        id
        amount
        poolId
        orderType
        timestamp
      }
    }
  }
`;
