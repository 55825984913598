import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import BigNumber from "bignumber.js";
import { ethers } from "ethers";
import _ from "lodash";
import { StakingState } from "../types";
import {
  fetchUserPoolData,
  fetchGameUserTokenInfo,
  fetchPoolDataWithPoolId,
  fetchPendingReward,
  fetchPendingRewardWithPoolId,
  fetchTrackedAmount,
  fetchPoolStakeAmount,
} from "./fetchGameUserData";

import fetchPools from "./fetchPools";

const initialState: StakingState = {
  userData: {
    userTokenData: {
      dptBalance: "0",
      lpBalance: "0",
      dptAllowance: "0",
      lpAllowance: "0",
      busdAllowance: "0",
      busdBalance: "0",
    },
    userPoolData: [],
    trackedAmount: 0,
    poolStakedAmount: 0,
  },
  poolInfo: [],
  isLoading: true,
};

export const gameSlice = createSlice({
  name: "staking",
  initialState,
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setPendingRewardToZero: (state, action) => {
      state.userData.userPoolData[action.payload].pendingReward = "0";
    },
    resetUserData: (state) => {
      state.userData = {
        userTokenData: {
          dptBalance: "0",
          lpBalance: "0",
          dptAllowance: "0",
          lpAllowance: "0",
          busdAllowance: "0",
          busdBalance: "0",
        },
        userPoolData: [],
        trackedAmount: 0,
        poolStakedAmount: 0,
      };
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchGameUserData.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchGameUserData.fulfilled, (state, action) => {
      state.userData.userTokenData = action.payload.userTokenData;
      state.userData.userPoolData = action.payload.userPoolData.map(
        (data, index) => {
          return {
            ...data,
            pendingReward: action.payload.pendingRewards[index],
          };
        }
      );
      state.userData.trackedAmount = action.payload.trackedAmount;
      state.userData.poolStakedAmount = action.payload.poolStakedAmount;

      state.isLoading = false;
    });
    builder.addCase(fetchUserTokenData.fulfilled, (state, action) => {
      state.userData.userTokenData = action.payload;
    });

    builder.addCase(fetchPoolsData.fulfilled, (state, action) => {
      state.poolInfo = action.payload;
    });
    builder.addCase(fetchUserStakedAmount.fulfilled, (state, action) => {
      state.userData.trackedAmount = action.payload.trackedAmount;
    });

    builder.addCase(fetchPoolWithId.fulfilled, (state, action) => {
      state.userData.userPoolData[action.payload.poolId] = {
        ...state.userData.userPoolData[action.payload.poolId],
        ...action.payload.userPoolInfo,
      };

      state.poolInfo[action.payload.poolId].totalAmount =
        action.payload.totalAmount;
      state.poolInfo[action.payload.poolId].apy = action.payload.apy;
    });
    builder.addCase(fetchPendingRewardAllPool.fulfilled, (state, action) => {
      action.payload.forEach((data, index) => {
        state.userData.userPoolData[index].pendingReward = data;
      });
    });
    // builder.addCase(fetchPendingReward1Pool.fulfilled, (state, action) => {
    //   state.userData.userPoolData[parseInt(action.payload.poolId) - 1].pendingReward = '0'
    // })
  },
});

// thunk

export const fetchGameUserData = createAsyncThunk<any, string>(
  "staking/fetchGameUserData",
  async (account) => {
    const fetchGameUserTokenInfoPromise = fetchGameUserTokenInfo(account);
    const fetchGameUserInfoPromise = fetchUserPoolData(account);
    const fetchPendingRewardPromise = fetchPendingReward(account);
    const fetchTrackedAmountPromise = fetchTrackedAmount(account);
    const fetchPoolStakeAmountPromise = fetchPoolStakeAmount();
    const [
      userTokenData,
      userPoolData,
      pendingRewards,
      trackedAmountRes,
      poolStakedAmountRes,
    ] = await Promise.all([
      fetchGameUserTokenInfoPromise,
      fetchGameUserInfoPromise,
      fetchPendingRewardPromise,
      fetchTrackedAmountPromise,
      fetchPoolStakeAmountPromise,
    ]);
    const trackedAmount = Number(ethers.utils.formatEther(trackedAmountRes));
    const poolStakedAmount = Number(
      ethers.utils.formatEther(poolStakedAmountRes)
    );
    return {
      userTokenData,
      userPoolData,
      pendingRewards,
      trackedAmount,
      poolStakedAmount,
    };
  }
);

export const fetchUserTokenData = createAsyncThunk(
  "staking/fetchUserTokenData",
  async (account: string) => {
    const userTokenData = await fetchGameUserTokenInfo(account);
    return userTokenData;
  }
);

export const fetchPoolsData = createAsyncThunk(
  "staking/fetchPools",
  async () => {
    const pools = await fetchPools();
    return pools;
  }
);

export const fetchPoolWithId = createAsyncThunk<any, any>(
  "staking/fetchPoolWithId",
  async ({ account, poolId }) => {
    const data = await fetchPoolDataWithPoolId(account, poolId);
    return data;
  }
);

export const fetchPendingRewardAllPool = createAsyncThunk(
  "staking/fetchPendingRewardAllPool",
  async (account: string) => {
    const pendingRewards = await fetchPendingReward(account);
    return pendingRewards;
  }
);

export const fetchPendingReward1Pool = createAsyncThunk<any, any>(
  "staking/fetchPendingRewardAllPool",
  async ({ account, poolId }) => {
    const pendingReward = await fetchPendingRewardWithPoolId(account, poolId);
    return { pendingReward, poolId };
  }
);

export const fetchUserStakedAmount = createAsyncThunk<any, any>(
  "staking/fetchUserStackedAmount",
  async (account: string) => {
    const trackedAmountRes = await fetchTrackedAmount(account);
    const trackedAmount = Number(ethers.utils.formatEther(trackedAmountRes));

    return {
      trackedAmount,
    };
  }
);

// actions
export const { setIsLoading, setPendingRewardToZero, resetUserData } =
  gameSlice.actions;

export default gameSlice.reducer;
