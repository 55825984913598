import styled from "styled-components";

import {
  fetchHistory,
  fetchUserLaunchpadTokenInfo,
  getPoolUser,
} from "state/launchpad/actions";
import { useEffect } from "react";
import { usePrismicDocumentByUID } from "@prismicio/react";
import {
  getAddress,
  getLaunchpadAddress,
  getLaunchpadV2Address,
} from "utils/addressHelpers";
import tokens from "config/constants/tokens";
import { useHistory, useParams } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import { useAppDispatch } from "state";
import { useSelector } from "react-redux";
import {
  useApproveToken,
  useBuyTribes,
  useClaimTribes,
  useRefundTribes,
} from "hooks/launchpad";
import { Spin } from "antd";
import { fetchGameUserData, fetchPoolsData } from "state/staking";
import { ITEMS_PER_PAGE } from "state/launchpad/constants";
import {
  getHistoryPaginationSelector,
  getIsLoadingLaunchpadSelector,
  getPoolLaunchpadSelector,
  getPoolUserLaunchpadSelector,
  getTransactionsSelector,
  getUserTokenLaunchpadSelector,
  setLaunchpadState,
  setPagination,
} from "state/launchpad";
import ItemProject from "./Components/ItemProjects";
import IdoProcess from "./Components/IdoProcess";
import Purchase from "./Components/Purchase";
import ListProject from "./Components/ListProject";
import PdfScroll from "./Components/PdfScroll";

function Project() {
  const { id }: { id: string } = useParams();
  const { push } = useHistory();
  const { account } = useWeb3React();
  const [project, { state }] = usePrismicDocumentByUID("project", id);

  const buyAction = useBuyTribes(project?.data?.is_public_for_everyone);
  const refundAction = useRefundTribes(project?.data?.is_public_for_everyone);
  const claimAction = useClaimTribes(project?.data?.is_public_for_everyone);
  const userTokenData = useSelector(getUserTokenLaunchpadSelector);
  const launchpadIsLoading = useSelector(getIsLoadingLaunchpadSelector);
  const transactions = useSelector(getTransactionsSelector);
  const paginationHistory = useSelector(getHistoryPaginationSelector);
  const projectLaunchpad = useSelector(getPoolLaunchpadSelector);
  const [onApproveLaunchpad] = useApproveToken(
    getAddress(tokens.busd.address),
    project?.data?.is_public_for_everyone
      ? getLaunchpadV2Address()
      : getLaunchpadAddress()
  );
  const user = useSelector(getPoolUserLaunchpadSelector);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (!id || state === "failed") {
      push("/");
    }
  }, [id, project, state]);

  useEffect(() => {
    if (project?.data && account) {
      dispatch(
        getPoolUser({
          account,
          pool: project?.data?.pool,
          isPublic: project?.data?.is_public_for_everyone,
        })
      );
      dispatch(
        fetchHistory({
          account,
          poolId: project?.data?.pool,
        })
      );
      dispatch(
        fetchUserLaunchpadTokenInfo({
          account,
          isPublic: project?.data?.is_public_for_everyone,
        })
      );
      dispatch(fetchGameUserData(account));
    }
  }, [project, account]);

  const handleSubmit = async (amount) => {
    if (userTokenData?.busdAllowance === "0") {
      await handleApproveBUSD();
    } else {
      dispatch(
        setLaunchpadState({
          isLoading: true,
        })
      );
      const tx = await buyAction(project?.data?.pool, +amount?.get);
      await tx?.wait();
      dispatch(
        getPoolUser({
          account,
          pool: project?.data?.pool,
          isPublic: project?.data?.is_public_for_everyone,
        })
      );
      dispatch(fetchGameUserData(account));
    }
  };
  const actions = (key) => async () => {
    dispatch(
      setLaunchpadState({
        isLoading: true,
      })
    );
    try {
      const tx = await {
        refund: refundAction,
        claim: claimAction,
      }?.[key]?.(project?.data?.pool);
      await tx?.wait();
      dispatch(
        getPoolUser({
          account,
          pool: project?.data?.pool,
          isPublic: project?.data?.is_public_for_everyone,
        })
      );
      dispatch(fetchGameUserData(account));
    } catch (error) {
      console.log("actions error", key, error);
    }
  };

  const handleApproveBUSD = async () => {
    try {
      dispatch(
        setLaunchpadState({
          isLoading: true,
        })
      );
      const tx = await onApproveLaunchpad();
      await tx?.wait();
      dispatch(
        fetchUserLaunchpadTokenInfo({
          account,
          isPublic: project?.data?.is_public_for_everyone,
        })
      );
    } catch (error) {
      console.log("error", error);
    }
  };
  const handleChangePagination = (page: number) => {
    dispatch(setPagination(page));
  };
  const updatedTransactions = transactions?.slice(
    paginationHistory?.currentPage * ITEMS_PER_PAGE,
    paginationHistory?.currentPage * ITEMS_PER_PAGE + ITEMS_PER_PAGE
  );
  return (
    <ProjectStyled>
      {state === "loading" ? (
        <LoadingWrapper>
          <Spin size="large" />
        </LoadingWrapper>
      ) : (
        <>
          <TwoColumn>
            <LeftContent>
              <ItemProject launchpad={projectLaunchpad} project={project} />
            </LeftContent>

            <RightContent>
              <IdoProcess project={project} />
              <Purchase
                project={project}
                user={user}
                isLoading={launchpadIsLoading}
                handleSubmit={handleSubmit}
                handleClaim={actions("claim")}
                handleRefund={actions("refund")}
                launchpad={projectLaunchpad}
                userTokenData={userTokenData}
              />
            </RightContent>
          </TwoColumn>

          <ListProject
            launchpad={projectLaunchpad}
            project={project}
            user={user}
            transactions={updatedTransactions}
            pagination={paginationHistory}
            handleChangePagination={handleChangePagination}
          />
          {project?.data?.white_paper?.url && (
            <PdfScroll
              title="White Paper"
              pdfUrl={project?.data?.white_paper?.url}
            />
          )}
        </>
      )}
    </ProjectStyled>
  );
}

const LoadingWrapper = styled.div`
  width: 100%;
  height: 80vh;
  display: grid;
  place-items: center;
`;
const ProjectStyled = styled.div`
  margin-top: 140px;
`;

const TwoColumn = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 30px;

  @media (min-width: 767px) {
    grid-template-columns: 500px 1fr;
  }
  @media (max-width: 1024px) {
    grid-template-columns: 300px 1fr;
  }
  @media (max-width: 500px) {
    grid-template-columns: unset;
  }
`;

const LeftContent = styled.div``;

const RightContent = styled.div``;
export default Project;
