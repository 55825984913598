import styled from "styled-components";

function Social({ socialMedias = null }) {
  const renderSocialMedias = () =>
    socialMedias?.map((socialMedia) => (
      <a target="_blank" rel="noreferrer" href={socialMedia?.social_link?.url}>
        <img src={socialMedia?.icon?.url} alt={socialMedia?.icon?.url} />
      </a>
    ));
  return (
    <SocialStyled>
      {socialMedias ? (
        renderSocialMedias()
      ) : (
        <>
          <a
            href="https://app.diviner.finance/#/"
            target="_blank"
            rel="noreferrer"
          >
            <img src="./images/homepage/icon-network.svg" alt="" />
          </a>

          <a
            href="https://medium.com/@Diviner_Protocol"
            target="_blank"
            rel="noreferrer"
          >
            <img src="./images/homepage/icon-medium.svg" alt="" />
          </a>

          <a
            href="https://twitter.com/DivinerProtocol"
            target="_blank"
            rel="noreferrer"
          >
            <img src="./images/homepage/icon-twitter.svg" alt="" />
          </a>

          <a
            href="https://t.me/DivinerProtocolOfficial"
            target="_blank"
            rel="noreferrer"
          >
            <img src="./images/homepage/icon-tele.svg" alt="" />
          </a>

          {/* <a href="/" target="_blank">
        <img src="./images/homepage/icon-discord.svg" alt=""/>
      </a>*/}

          <a
            href="https://www.youtube.com/channel/UCOmZZoKvY1bDhcE49BiqcDA"
            target="_blank"
            rel="noreferrer"
          >
            <img src="./images/homepage/icon-youtube.svg" alt="" />
          </a>
        </>
      )}
    </SocialStyled>
  );
}

const SocialStyled = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 17px;
  margin-bottom: 16px;

  a {
    img {
      transition: 0.5s ease-in-out;
      cursor: pointer;
    }

    &:hover {
      img {
        transform: scale(1.2);
        transition: 0.5s ease-in-out;
      }
    }
  }
`;

export default Social;
