import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { Document, Page, pdfjs } from "react-pdf";
import { Spin } from "antd";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;
function PdfSroll({ pdfUrl, title }) {
  const [totalPages, setTotalPages] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setTotalPages(numPages);
    setIsLoaded(true);
  }

  return (
    <PdfSrollStyled>
      {isLoaded && <h2>{title}</h2>}
      {useMemo(
        () => (
          <Document
            className={`${isLoaded ? "loaded" : ""}`}
            file={{
              url: pdfUrl,
            }}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            {isLoaded ? (
              new Array(totalPages)
                .fill(0)
                .map((_, idx) => <Page width={1340} pageNumber={idx + 1} />)
            ) : (
              <Spin />
            )}
          </Document>
        ),

        [pdfUrl, totalPages, isLoaded]
      )}
    </PdfSrollStyled>
  );
}

const PdfSrollStyled = styled.div`
  width: 100%;
  padding: 50px 0;
  h2 {
    font-size: 26px;
    color: #fff;
    padding-bottom: 16px;
  }
  .react-pdf {
    &__Document {
      &.loaded {
        width: 100%;
        height: 737px;
        overflow-y: scroll;
        overflow-x: hidden;
      }
      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgb(255, 255, 255);
        background-color: #ffffff;
        border-radius: 8px;
      }

      &::-webkit-scrollbar {
        width: 12px;
        background-color: #f5f5f5;
        border-radius: 8px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #1897ff;
        border-radius: 8px;
      }
      .react-pdf__Page__canvas {
        width: 100% !important;
        height: auto !important;
      }
    }

    &__message {
      display: none;
    }
  }
`;

export default PdfSroll;
