import { ModalProvider } from "@diviner-protocol/uikit";
import { createWeb3ReactRoot, Web3ReactProvider } from "@web3-react/core";
import { RefreshContextProvider } from "contexts/RefreshContext";
import { ThemeContextProvider } from "contexts/ThemeContext";
import React from "react";
import { HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import store from "state";
import getLibrary from "utils/web3React";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";

const Web3ProviderNetwork = createWeb3ReactRoot("NETWORK");
export const client = new ApolloClient({
  uri: process.env.REACT_APP_GRAPHQL_LAUNCHPAD,
  cache: new InMemoryCache(),
});
const Providers: React.FC = ({ children }) => {
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <Web3ProviderNetwork getLibrary={getLibrary}>
        <Provider store={store}>
          <HelmetProvider>
            <ApolloProvider client={client}>
              <ThemeContextProvider>
                <RefreshContextProvider>
                  <ModalProvider>{children}</ModalProvider>
                </RefreshContextProvider>
              </ThemeContextProvider>
            </ApolloProvider>
          </HelmetProvider>
        </Provider>
      </Web3ProviderNetwork>
    </Web3ReactProvider>
  );
};

export default Providers;
