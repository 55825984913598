import GlobalModal from "components/static/GlobalModal";
import { useActiveWeb3React } from "hooks";
import useAuth from "hooks/useAuth";
import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import styled from "styled-components";
import { addCommas } from "utils/formatNumber";
import CardValue from "./CardValue";
import ConnectWalletModal from "./Modal/ConnectWalletModal";

const tokenList = {
  bnb: {
    img: "/images/market/binance.svg",
  },
  busd: {
    img: "/images/market/busd.svg",
  },
  dpt: {
    img: "/images/tokens/dpt.svg",
  },
};

function Header({ bnbBalance, busdBalance, dptBalance }) {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 10);
    });
  }, []);
  const { account } = useActiveWeb3React();
  const { logout } = useAuth();
  const trimAccount = account
    ? `${account.slice(0, 4)}...${account.slice(38)}`
    : "0x00...0000"; // account length: 42

  return (
    <HeaderStyled className={scroll ? "active" : "close"}>
      <div className="header">
        <Burger
          className={`burger menubar ${isCollapsed ? "close" : "is-active"} `}
          onClick={() => setIsCollapsed(!isCollapsed)}
        >
          <span></span>
          <span></span>
          <span></span>
        </Burger>

        <Link className="header__logo" to="/">
          <img src="/logo.png" alt="logo" className="header__logo-img" />
        </Link>

        <BoxMenu className={`${isCollapsed ? "close" : "active"}`}>
          <ul className="header__menu">
            <li>
              <NavLink to="/" exact>
                Home
              </NavLink>
            </li>

            {/* <li>
							<NavLink to="/project">Project</NavLink>
						</li> */}

            <li>
              <NavLink to="/staking">Staking</NavLink>
            </li>

            <li>
              <a
                href="https://app.diviner.finance/#/"
                target="_blank"
                rel="noreferrer"
              >
                Game
              </a>
            </li>
          </ul>

          {!account ? (
            <div className="header"></div>
          ) : (
            <div className="header">
              <div className="header__price mobile">
                <div className="header__price--content">
                  <p>{addCommas(busdBalance)}</p>
                  <img src={tokenList.busd.img} alt="price.svg" />
                </div>

                <div className="header__price--content">
                  <p>
                    <CardValue value={addCommas(dptBalance)} />
                  </p>
                  <img src={tokenList.dpt.img} alt="price.svg" />
                </div>

                <div className="header__price--content">
                  <p>{addCommas(bnbBalance)}</p>
                  <img src={tokenList.bnb.img} alt="price.svg" />
                </div>
              </div>
            </div>
          )}
        </BoxMenu>
      </div>

      {!account ? (
        <div className="header">
          <button
            className="header__button"
            type="submit"
            onClick={() => {
              GlobalModal.show(
                <ConnectWalletModal onClose={GlobalModal.hide} />
              );
            }}
          >
            <p>Connect wallet</p>
            <img src="./images/icon-wallet.svg" alt="connect wallet" />
          </button>
        </div>
      ) : (
        <div className="header">
          <div className="header__price">
            <div className="header__price--content">
							<p>{addCommas(busdBalance)}</p>
							<img src={tokenList.busd.img} alt="price.svg" />
						</div>

            <div className="header__price--content">
              <p>
                <CardValue value={parseFloat(dptBalance)} decimals={1} />
              </p>
              <img src={tokenList.dpt.img} alt="price.svg" />
            </div>

            <div className="header__price--content">
              <p>
                <CardValue value={parseFloat(bnbBalance)} decimals={1} />
              </p>
              <img src={tokenList.bnb.img} alt="price.svg" />
            </div>
            <div className="header__account">
              {!account ? <p>Connect wallet</p> : <p>{trimAccount}</p>}
            </div>
          </div>

          <div className="header__account mobile">
            {!account ? <p>Connect wallet</p> : <p>{trimAccount}</p>}
          </div>

          <button className="header__button" type="submit" onClick={logout}>
            <p>Logout</p>
            <img src="./images/icon-wallet.svg" alt="connect wallet" />
          </button>
        </div>
      )}
    </HeaderStyled>
  );
}

const BoxMenu = styled.div`
  z-index: 3;
  position: absolute;
  top: 14px;
  left: -100%;
  width: 75%;
  height: 532px;
  overflow: hidden;
  background: rgba(0, 6, 10, 1);
  padding: 42px 0 20px;
  box-sizing: border-box;
  transition: all 0.3s cubic-bezier(0.27, 0.16, 0.36, 0.99);

  @media (min-width: 1025px) {
    display: flex;
    justify-content: flex-start;
    z-index: 3;
    position: unset;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    overflow: hidden;
    background: transparent;
    padding: 0;
  }

  &.active {
    left: 14px;

    ul {
      list-style-type: none;
      width: 100%;
      padding: 0;
      @media (max-width: 1025px) {
        display: block;
      }

      @media (max-width: 767px) {
        padding-top: 10px;
      }

      li {
        transition: all 0.3s cubic-bezier(0.27, 0.16, 0.36, 0.99);
        position: relative;
        left: 0;
        position: relative;
        padding: 10px;
        font-size: 24px;
        cursor: pointer;
        text-align: center;

        @media (max-width: 769px) {
          font-size: 1.2em;
          padding: 15px 10px;
        }

        &:nth-of-type(1) {
          transition-delay: 0.2s;
        }
        &:nth-of-type(2) {
          transition-delay: 0.25s;
        }
        &:nth-of-type(3) {
          transition-delay: 0.3s;
        }
        &:nth-of-type(4) {
          transition-delay: 0.35s;
        }
        &:nth-of-type(5) {
          transition-delay: 0.4s;
        }
        &:nth-of-type(6) {
          transition-delay: 0.45s;
        }
        &:nth-of-type(7) {
          transition-delay: 0.5s;
        }
        &:nth-of-type(8) {
          transition-delay: 0.55s;
        }
        &:nth-of-type(9) {
          transition-delay: 0.6s;
        }
        &:nth-of-type(10) {
          transition-delay: 0.65s;
        }

        a {
          color: ${(props) => props.theme.colors.white};
          font-weight: 600;
        }
      }
    }

    &a {
      color: #ffe600;
      position: relative;

      :before {
        content: "";
        position: absolute;
        top: 50%;
        left: -20px;
        width: 25px;
        height: 25px;
        transform: translate(-50%, -50%);
        background-size: cover;
      }
    }
  }
`;

const Burger = styled.div`
  position: absolute;
  top: 21px;
  left: 21px;
  z-index: 4;
  cursor: pointer;
  display: block;

  @media (min-width: 1025px) {
    display: none;
  }

  &.is-active {
    span {
      transition: transform 0.6s cubic-bezier(0.54, 0.1, 0.42, 1.48),
        width 0.6s ease-in;

      &:first-child {
        transform: rotate(45deg) translate(5px, -5px);
        width: 19px;
      }

      &:nth-child(2) {
        transform: rotate(-45deg);
        width: 38px;
      }

      &:last-child {
        transform: rotate(45deg) translate(12px, -10px);
        width: 19px;
      }
    }
  }

  span {
    display: block;
    height: 5px;
    background: linear-gradient(
      0deg,
      rgba(247, 224, 0, 1) 0%,
      rgba(239, 152, 0, 1) 100%
    );
    border-radius: 20px;
    transition: transform 0.6s cubic-bezier(0.54, 0.1, 0.42, 1.48),
      width 0.6s ease-out;

    &:not(:last-child) {
      margin-bottom: 7px;
    }

    &:first-child {
      transform-origin: 0 50%;
      width: 20px;
    }

    &:nth-child(2) {
      width: 37px;
    }

    &:last-child {
      transform-origin: 100% 50%;
      width: 30px;
    }
  }
`;

const HeaderStyled = styled.div`
  background: #1a48a1;
  display: grid;
  grid-template-columns: 130px 140px 100px;
  gap: 20px;
  height: 80px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;

  .header__logo {
    @media (max-width: 1024px) {
      margin-left: 40px;
    }
  }

  @media (min-width: 767px) {
    height: auto;
    display: flex;
    justify-content: unset;
  }

  &.active {
    @media (min-width: 767px) {
      top: 0;
      display: block;
      transition-duration: 0.6s;
    }
  }

  p {
    margin: 0;
    color: #bac8e3;
    font-size: 18px;
    font-weight: 500;
  }

  .header {
    display: flex;
    justify-content: space-around;
    align-items: center;

    &__logo {
      @media (max-width: 768px) {
        margin: 10px;
      }

      @media (max-width: 767px) {
        text-align: center;
        margin: 0 auto;
      }

      &-img {
        padding: 0px 60px;
        width: 200px;

        @media (min-width: 769px) {
          width: auto;
        }
      }
    }

    &__menu {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      list-style: none;
      margin-bottom: 0;

      @media (min-width: 769px) {
        flex-direction: unset;
      }

      li {
        @media (max-width: 1024px) {
          width: 100%;
          padding: 0 !important;
        }

        a {
          padding: 20px;
          transition: ease all 0.5s;
          border-bottom: solid transparent 6px;
          cursor: pointer;
          color: #ffffff;
          font-size: 1.2rem;
          font-weight: 400;
          white-space: nowrap;
          display: block;

          @media (max-width: 767px) {
            width: 100%;
            padding: 15px;
          }

          &:hover {
            background: #2a62b4;
            border-bottom: solid #10a7f3 6px;
          }

          &.active {
            background: #2a62b4;
            border-bottom: solid #10a7f3 6px;
            color: #fff;

            p {
              color: #ffffff;
              font-size: 1.2rem;
              font-weight: 500;
            }
          }
        }
      }
    }

    &__price {
      display: flex;
      justify-content: left;
      align-items: center;
      background: #123271;
      border-radius: 30px;
      padding: 5px;
      height: 46px;

      @media (max-width: 767px) {
        display: none;
      }

      &.mobile {
        display: block;
        width: 100%;
        background: transparent;

        @media (min-width: 767px) {
          display: none;
        }
      }

      p {
        color: #fff;
        font-size: 16px;
        font-weight: 400;

        @media (max-width: 769px) {
          width: 61px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        @media (max-width: 767px) {
          width: auto;
        }
      }

      &--content {
        display: flex;
        justify-content: left;
        align-items: center;
        padding: 0px 15px;

        @media (max-width: 767px) {
          margin-bottom: 15px;
          justify-content: space-between;
          background-color: #123271;
          border-radius: 16px;
          padding: 10px 20px;
        }

        &:nth-child(2) {
          border-right: none;

          @media (min-width: 767px) {
            border-right: 1px solid #fff;
          }
        }

        &:nth-child(1) {
          border-right: none;

          @media (min-width: 767px) {
            border-right: 1px solid #fff;
          }
        }

        p {
          margin-bottom: 0;
        }

        img {
          padding-left: 5px;
          width: 24px;
        }
      }
    }

    &__account {
      padding-left: 10px;
      padding-right: 5px;

      &.mobile {
        display: block;

        @media (min-width: 767px) {
          display: none;
        }
        p {
          width: 120px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      p {
        margin-bottom: 0;
      }
    }

    &__button {
      background: url("./images/market/header-button.png") no-repeat;
      background-size: 100% 100%;
      cursor: pointer;
      width: 100%;
      max-width: 160px;
      height: 46px;
      line-height: 46px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 20px;
      margin-left: 20px;
      padding: 0 20px;
      transform: scale(1);
      transition: 0.5s ease-in-out;
      border: none;

      @media (max-width: 767px) {
        background: unset;
        position: absolute;
        top: 11px;
        right: -61px;
      }

      &:hover {
        transform: scale(1.1);
        transition: 0.5s ease-in-out;
      }

      p {
        color: #fff;
        font-size: 16px;
        margin-bottom: 0;
        display: none;
        white-space: nowrap;

        @media (min-width: 767px) {
          display: block;
        }
      }

      img {
        display: block;
        max-width: 30px;

        @media (min-width: 767px) {
          display: none;
        }
      }
    }
  }

  .header {
    &:nth-child(1) {
      justify-content: left;
      width: 100%;

      @media (min-width: 769px) {
        width: 60%;
      }
    }
  }

  .header {
    &:nth-child(2) {
      justify-content: flex-start;
      width: 100%;

      @media (min-width: 767px) {
        width: 40%;
        justify-content: right;
      }
    }
  }
`;
export default Header;
