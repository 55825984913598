import React from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import { Tabs, Table } from "antd";
import { usePoolsSelector, useUserDataSelector } from "state/staking/selector";
import { roundUsing } from "utils/helpers";
import moment from "moment";
import { ArrowLeft, ArrowRight } from "react-feather";
const { TabPane } = Tabs;

function callback(key) {
  console.log(key);
}

function ListProject({
  project,
  user,
  launchpad,
  transactions,
  pagination,
  handleChangePagination,
}) {
  const userData = useUserDataSelector();

  // const guaAllocations = roundUsing(
  //   Math.floor,
  //   ((userData?.trackedAmount * +launchpad?.buyRate) /
  //     10000 /
  //     launchpad?.releaseAmount) *
  //     100,
  //   2
  // );
  const guaAllocations =
    user?.stakingTicket > launchpad?.minStakedAmount
      ? roundUsing(
          Math.floor,
          ((user?.stakingTicket * +launchpad?.buyRate) /
            10000 /
            launchpad?.releaseAmount) *
            100,
          2
        )
      : 0;
  const maxAmount =
    user?.stakingTicket > launchpad?.minStakedAmount
      ? roundUsing(
          Math.floor,
          (user?.stakingTicket * +launchpad?.buyRate) / 10000,
          2
        )
      : 0;
  const communityAllocations = roundUsing(
    Math.floor,
    ((user?.whitelistAmount ?? 0) / (launchpad?.totalWhitelistAmount || 1)) *
      100,
    2
  );
  const calculateReleaseRate =
    (isToken = true) =>
    (value, fixed = null) => {
      const updatedValue = isToken
        ? (+value * +(launchpad?.releaseRate ?? 1)) / 10000
        : (+value / +(launchpad?.releaseRate ?? 1)) * 10000;
      if (fixed) return updatedValue.toFixed(fixed);
      return updatedValue;
    };
  const [tokenPrice] = project?.data?.token_price || [];
  const [total] = project?.data?.total || [];
  const mapTokenAmount = calculateReleaseRate(true);
  const maxToken = mapTokenAmount(maxAmount, 4);
  const amountWhiteList = +user?.whitelistAmount || 0;
  const maxWhitelistToken = mapTokenAmount(amountWhiteList, 4) || 0;

  const renderRows = (items) =>
    items?.map((item, idx) => (
      <tr key={`${item?.label}-${idx}`}>
        <td className="ant-table-cell">{item?.label}</td>
        <td className="ant-table-cell">
          {item?.text?.indexOf("http") > -1 ? (
            <a href={item?.text} rel="noreferrer" target="_blank">
              {item?.text}
            </a>
          ) : (
            item?.text
          )}
          {item?.date}
        </td>
      </tr>
    ));
  const mapOrderType = (orderType, amount) => {
    switch (orderType) {
      case "Buy":
        return `Purchase ${amount} ${total?.unit} - ${mapTokenAmount(
          amount,
          4
        )} ${tokenPrice?.unit} `;

      default:
        return `${orderType} ${amount} ${total?.unit}`;
    }
  };
  const renderTransactions = (items) =>
    items.map((item, idx) => (
      <tr key={`trans-${idx}`}>
        <td colSpan={2} className="ant-table-cell">
          {idx + 1}
        </td>
        <td className="ant-table-cell">
          {moment(item?.timestamp * 1000).format("DD/MM/YYYY hh:mm a")}
        </td>
        <td className="ant-table-cell">
          {mapOrderType(item?.orderType, item?.amount)}
        </td>
      </tr>
    ));
  const inprogressStep = project?.data?.process?.findIndex(
    (item) => new Date(item?.date).getTime() - Date.now() > 0
  );
  const isShow = inprogressStep - 1 >= 1 || inprogressStep === -1;
  const isPublic = project?.data?.is_public_for_everyone;
  return (
    <BoxTabs>
      <Tabs defaultActiveKey="1" onChange={callback}>
        <TabPane tab="Project Details" key="1">
          <WrapStyled>
            <LeftContent>
              <div className="ant-table">
                <table>
                  <tbody className="ant-table-tbody">
                    {renderRows(project?.data?.project_detail_left)}
                  </tbody>
                </table>
              </div>
            </LeftContent>

            <RightContent>
              <div className="ant-table">
                <table>
                  <tbody className="ant-table-tbody">
                    {/* <tr>
                      <td className="ant-table-cell">Name</td>
                      <td className="ant-table-cell">MetaVPad</td>
                    </tr>

                    <tr>
                      <td className="ant-table-cell">Token Symbol</td>
                      <td className="ant-table-cell">METAV</td>
                    </tr>
                    <tr>
                      <td className="ant-table-cell">IDO Supply:</td>
                      <td className="ant-table-cell">625000 METAV</td>
                    </tr>
                    <tr>
                      <td className="ant-table-cell">Total Supply:</td>
                      <td className="ant-table-cell">200000000 METAV</td>
                    </tr> */}
                    {renderRows(project?.data?.project_detail_right)}
                  </tbody>
                </table>
              </div>
            </RightContent>
          </WrapStyled>
        </TabPane>

        {!isPublic && (
          <TabPane tab="Your Allocation" key="2">
            <WrapStyled>
              <div className="ant-table">
                <table>
                  <tbody className="ant-table-tbody">
                    <tr>
                      <td colSpan={2} className="ant-table-cell">
                        No.
                      </td>
                      <td className="ant-table-cell">Type</td>
                      <td className="ant-table-cell">Allocation</td>
                      <td className="ant-table-cell">Max Purchase</td>
                    </tr>
                    <tr>
                      <td colSpan={2} className="ant-table-cell">
                        1
                      </td>
                      <td className="ant-table-cell">Guaranteed</td>
                      <td className="ant-table-cell">
                        {isShow && `${guaAllocations || 0}%`}
                      </td>
                      <td className="ant-table-cell">
                        {isShow && (
                          <p>
                            {maxAmount || 0} {total?.unit} - {maxToken || 0}{" "}
                            {tokenPrice?.unit}
                          </p>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2} className="ant-table-cell">
                        2
                      </td>
                      <td className="ant-table-cell">Community</td>
                      <td className="ant-table-cell">
                        {isShow && `${communityAllocations}%`}
                      </td>
                      <td className="ant-table-cell">
                        {isShow && (
                          <p>
                            {amountWhiteList || 0} {total?.unit} -{" "}
                            {maxWhitelistToken || 0} {tokenPrice?.unit}
                          </p>
                        )}{" "}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </WrapStyled>
          </TabPane>
        )}

        <TabPane tab="History" key="3">
          {pagination?.totalPage > 0 && (
            <>
              <WrapStyled>
                <div className="ant-table">
                  <table>
                    <tbody className="ant-table-tbody">
                      <tr>
                        <td colSpan={2} className="ant-table-cell">
                          No.
                        </td>
                        <td className="ant-table-cell">Date</td>
                        <td className="ant-table-cell"> Action</td>
                      </tr>
                      {renderTransactions(transactions)}
                    </tbody>
                  </table>
                </div>
              </WrapStyled>
              <Pagination>
                <ArrowLeft
                  className={pagination?.currentPage <= 0 ? "disable" : ""}
                  onClick={() =>
                    handleChangePagination(pagination?.currentPage - 1)
                  }
                />
                Page {pagination?.currentPage + 1} of {pagination?.totalPage}
                <ArrowRight
                  className={
                    pagination?.currentPage >= pagination?.totalPage - 1
                      ? "disable"
                      : ""
                  }
                  onClick={() =>
                    handleChangePagination(pagination?.currentPage + 1)
                  }
                />
              </Pagination>
            </>
          )}
        </TabPane>
      </Tabs>
    </BoxTabs>
  );
}

const Pagination = styled.div`
  color: #fff;
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: flex-end;
  margin-top: 16px;
  svg {
    cursor: pointer;
    &.disable {
      cursor: pointer;
      pointer-events: none;
      opacity: 0.5;
    }
  }
`;

const BoxTabs = styled.div`
  margin-bottom: 30px;

  .ant-tabs-nav {
    margin: 56px 0px 46px 0px;
  }

  .ant-tabs {
    overflow: visible;
  }

  .ant-tabs-tab {
    font-size: 1.6rem;
    font-weight: 500;
    color: #ffffff;

    @media (max-width: 768px) {
      font-size: 1rem;
    }

    &.ant-tabs-tab-active {
      font-weight: 600;
    }
  }

  .ant-pagination {
    display: none !important;
  }

  .ant-table {
    background: transparent !important;

    tr {
      &:nth-child(odd) {
        td {
          background: rgba(196, 196, 196, 0.12);
        }
      }

      td {
        border: 1px solid rgba(255, 255, 255, 0.5);
        font-size: 1rem;
        color: #ffffff;

        @media (min-width: 767px) {
          font-size: 1.2rem;
        }

        &:first-child {
          white-space: nowrap;
          width: 1px;
        }

        .ant-table-cell {
          a {
            @media (max-width: 767px) {
              width: 135px;
              display: block;
            }
          }
        }
      }
    }
  }
`;

const WrapStyled = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;

  div:nth-child(2) {
    .ant-table {
      background: rgba(196, 196, 196, 0.12) !important;
      tr {
        &:nth-child(odd) {
          td {
            background: #15244e;
          }
        }
      }
    }
  }
  @media (min-width: 767px) {
    grid-template-columns: repeat(auto-fit, minmax(480px, 1fr));
  }
`;

const LeftContent = styled.div``;

const RightContent = styled.div``;
export default ListProject;
