import { useWeb3React } from "@web3-react/core";
import { ethers } from "ethers";
import { useActiveWeb3React } from "hooks";
import { useCallback, useEffect, useMemo, useState } from "react";
import { calculateGasMargin } from "utils";
import { getBigNumber } from "utils/bigNumber";
import { TransactionResponse } from "@ethersproject/providers";
import { MaxUint256 } from "@ethersproject/constants";
import { useTransactionAdder } from "state/transactions/hooks";
import { useLaunchpadContract, useTokenContract } from "./useContract";

export const useBuyTribes = (isPublic = false) => {
  const { account } = useWeb3React();
  const launchpadContract = useLaunchpadContract(isPublic);
  const buyAction = useCallback(
    async (pool: number, amount: number) => {
      try {
        const value = ethers.utils.parseEther(amount.toString()).toString();
        const response = await launchpadContract.estimateGas
          .buy(pool, value)
          .catch(() => {
            return launchpadContract.estimateGas.buy(pool, value);
          });
        const txHash = await launchpadContract.buy(pool, value, {
          gasLimit: calculateGasMargin(response),
        });
        return txHash;
      } catch (error) {
        console.log("err", error);
      }
    },
    [account, launchpadContract]
  );

  return buyAction;
};

export const useRefundTribes = (isPublic = false) => {
  const { account } = useWeb3React();
  const launchpadContract = useLaunchpadContract(isPublic);
  const refundAction = useCallback(
    async (pool: number) => {
      try {
        const response = await launchpadContract.estimateGas
          .refund(pool)
          .catch(() => {
            return launchpadContract.estimateGas.refund(pool);
          });
        const txHash = await launchpadContract.refund(pool, {
          gasLimit: calculateGasMargin(response),
        });
        return txHash;
      } catch (error) {
        console.log("err", error);
      }
    },
    [account, launchpadContract]
  );

  return refundAction;
};

export const useClaimTribes = (isPublic = false) => {
  const { account } = useWeb3React();
  const launchpadContract = useLaunchpadContract(isPublic);
  const claimAction = useCallback(
    async (pool: number) => {
      try {
        const response = await launchpadContract.estimateGas
          .claim(pool)
          .catch(() => {
            return launchpadContract.estimateGas.claim(pool);
          });
        const txHash = await launchpadContract.claim(pool, {
          gasLimit: calculateGasMargin(response),
        });
        return txHash;
      } catch (error) {
        console.log("err", error);
      }
    },
    [account, launchpadContract]
  );

  return claimAction;
};

export function useApproveToken(
  token?: any,
  addressNeedApprove?: string
): [() => Promise<TransactionResponse>] {
  const tokenContract = useTokenContract(token);

  const approve = useCallback(async (): Promise<TransactionResponse> => {
    const estimatedGas = await tokenContract.estimateGas
      .approve(addressNeedApprove, MaxUint256)
      .catch(() => {
        return tokenContract.estimateGas.approve(
          addressNeedApprove,
          MaxUint256
        );
      });

    return tokenContract
      .approve(addressNeedApprove, MaxUint256, {
        gasLimit: calculateGasMargin(estimatedGas),
      })
      .then((response: TransactionResponse) => {
        // addTransaction(response, {
        //   summary: `Approve  successfully!`,
        // })
        return response;
      })
      .catch((error: Error) => {
        console.debug("Failed to approve token", error);
        throw error;
      });
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [tokenContract, addressNeedApprove]);

  return [approve];
}

// export const useAccountLaunchpad = (pool, account) => {
//   const launchpadContract = useLaunchpadContract();
//   return useMemo(async () => {
//     if (account) {
//       const response = await launchpadContract.users(pool, account);
//       return response;
//     }
//     return null;
//   }, [pool, account]);
// };
