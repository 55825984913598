import { MaxUint256 } from "@ethersproject/constants";
import { TransactionResponse } from "@ethersproject/providers";
import { useCallback } from "react";
import { useTransactionAdder } from "state/transactions/hooks";
import { calculateGasMargin } from "utils";
import { useTokenContract } from "./useContract";
import { useAppDispatch } from "../state/index";
import {
  finalizeTransaction,
  errorTransaction,
} from "../state/transactions/actions";
import { getCallData } from "../state/transactions/utils";

// returns a variable indicating the state of the approval and a function which approves if necessary or early returns
export function useApproveCallbackCustom(
  token?: any,
  addressNeedApprove?: string
): [() => Promise<TransactionResponse>] {
  const tokenContract = useTokenContract(token);
  const addTransaction = useTransactionAdder();

  const approve = useCallback(async (): Promise<TransactionResponse> => {
    const estimatedGas = await tokenContract.estimateGas
      .approve(addressNeedApprove, MaxUint256)
      .catch(() => {
        return tokenContract.estimateGas.approve(
          addressNeedApprove,
          MaxUint256
        );
      });

    return tokenContract
      .approve(addressNeedApprove, MaxUint256, {
        gasLimit: calculateGasMargin(estimatedGas),
      })
      .then((response: TransactionResponse) => {
        // addTransaction(response, {
        //   summary: `Approve  successfully!`,
        // })
        return response;
      })
      .catch((error: Error) => {
        console.debug("Failed to approve token", error);
        throw error;
      });
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [tokenContract, addressNeedApprove]);

  return [approve];
}
