import { Tabs } from "antd";
import { useActiveWeb3React } from "hooks";
import React, { useEffect } from "react";
import { useAppDispatch } from "state";
import { fetchGameUserData, fetchPoolsData } from "state/staking";
import { usePoolsSelector } from "state/staking/selector";
import styled from "styled-components";
import Staking from "./Components/StakingColumn";
import TrackedAmount from "./Components/TrackedAmount";

const { TabPane } = Tabs;

function PageStaking() {
  const { account } = useActiveWeb3React();
  const dispatch = useAppDispatch();
  const pools = usePoolsSelector();

  const dptPools = pools.slice(0, 3);
  const lpPools = pools.slice(3, 6);
  useEffect(() => {
    dispatch(fetchPoolsData());
    if (account) dispatch(fetchGameUserData(account));
  }, [account]);

  return (
    <PageStakingStyled>
      <TrackedAmount />
      <Tabs defaultActiveKey="1">
        <TabPane tab="Live" key="1">
          <Staking stakingList={dptPools} />
          <Staking stakingList={lpPools} />
        </TabPane>
        <TabPane tab="Finished" key="2">
          <Staking stakingList={[]} />
          <Staking stakingList={[]} />
        </TabPane>
      </Tabs>
    </PageStakingStyled>
  );
}

const PageStakingStyled = styled.div`
  padding-top: 150px;
  .ant-tabs-nav {
    width: 100%;
    margin: 0 auto;
    margin-bottom: 80px;

    &::before {
      border-radius: 17px 17px 0px 0px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.24);
    }

    .ant-tabs-nav-wrap {
      justify-content: space-between;

      .ant-tabs-tab {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        .ant-tabs-tab-btn {
          color: #a6c2e3;
          font-size: 26px;
          font-weight: 800;
        }
      }
      .ant-tabs-tab.ant-tabs-tab-active {
        .ant-tabs-tab-btn {
          color: #bddbfe;
        }
      }
    }
  }
`;

export default PageStaking;
