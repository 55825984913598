import stakingAbi from "config/abi/Staking.json";
import { getStakingAddress } from "utils/addressHelpers";
import multicall from "utils/multicall";
import { stakingConfig } from "config/constants/staking";
import { getPoolApy } from "utils/apy";
import { ethers } from "ethers";
import { DPT_BNB_PRICE, DPT_PRICE } from "config/constants/price";

const fetchPools = async () => {
  try {
    const calls = [];
    for (let i = 0; i <= 5; i++)
      calls.push({ address: getStakingAddress(), name: "pools", params: [i] });

    const results = await multicall(stakingAbi, calls, {
      requireSuccess: false,
    });
    return [
      ...results.map((pool, index) => {
        const apy = getPoolApy(
          index < 3 ? DPT_PRICE : DPT_BNB_PRICE,
          DPT_PRICE,
          parseFloat(ethers.utils.formatEther(pool.totalAmount.toString())),
          parseFloat(ethers.utils.formatEther(pool.rewardPerBlock.toString()))
        );

        return {
          totalAmount: pool.totalAmount.toString(),
          lockingTime: pool.lockingTime.toNumber(),
          endBlock: pool.endBlock.toNumber(),
          apy: apy ? apy?.toString() : "0",
          multiplyForTicket: pool.multiplyForTicket?.toNumber() / 10,
          ...stakingConfig[index],
        };
      }),
    ];
  } catch (error) {
    console.log("error fetchPools", error);
    return [
      {
        totalAmount: "0",
        endBlock: 0,
        timeLocking: "0",
      },
    ];
  }
};

export default fetchPools;
