export default {
  mulltiCall: {
    56: "0xfF6FD90A470Aaa0c1B8A54681746b07AcdFedc9B",
    97: "0x8F3273Fb89B075b1645095ABaC6ed17B2d4Bc576",
  },

  staking: {
    56: "0x6c7097C98818d1fCce78E28B3890C876d5a80525",
    97: "0x13D2988b74aC76e745DAEe8098918125306AA7dA",
  },

  prediction: {
    56: [
      "0x562b441959Aa00b12d00a1725992C253FB705f05",
      "0x44882ed1C425083424Ed49D26b8D504d6BE1C8Db",
      "0x06456Cd5497E801c40c41856c824a67374a31EE7",
    ],
    97: [
      "0x38A40C0473876CACE7B62edC0C0edeF826edc5b2",
      "0x38A40C0473876CACE7B62edC0C0edeF826edc5b2",
      "0x38A40C0473876CACE7B62edC0C0edeF826edc5b2",
    ],
  },

  game: {
    56: "0x0506871B68E5823693E115a5e2175b9A2c4CFB42",
    97: "0xFe70F3F60894E36BcFD3dc1521aFaFb7A849E0F6",
  },
  character: {
    56: "0xF46b29f3A8Cc3Bee845803C11a56251919A5f6c7",
    97: "0x0FeE1300e857b1bA4476483C5e278aeC9387bb3B",
  },
  equipment: {
    56: "0xAdD5a0B24E7F987e8FAB863e86BaB1F583c6DFB6",
    97: "0x7EDD7F5aAe70E8F7795E5101F6f672A9Fb1e3cCB",
  },

  priceOracle: {
    56: "0xFBFb4C4E10EBD70DcE28B8b00FdAD6c8e5De38dc",
    97: "0x5aA7C4a25c9E3db2bCAe6E08dC2Bfe8aCed73730",
  },

  gameWiki: {
    56: "0x7d88930d354BcB4cBB2F28eD4561b267baf147fA",
    97: "0xDf4f2E52078135365f33eF5cB541A9e7c0aa1B35",
  },

  faucet: {
    56: "0x23c41D28A239dDCAABd1bb1deF8d057189510066",
    97: "0xe72A4D14d49568dEE390aCebFf18bD498eDd127D",
  },

  market: {
    56: "",
    97: "0x294Ea451bb3A63Db8A2CA1DEE0BCfFe98fe33E5E",
  },
  tokenTransferProxy: {
    56: "123",
    97: "0xa0c715AdB93C7D84961Abd6C3bDB66AEb276806E",
  },
  nftsTransferProxy: {
    56: "",
    97: "0x3544996b0Bce0FF5D6a99820148eE005208F9019",
  },

  fakeCharacter: {
    56: "",
    97: "0x26656936D36148320a0d83cE75958B0aFE0d9cD2",
  },

  buyChest: {
    56: "0x2Af4A1d58E12de09306AAc5Bc108ce03459BE226",
    97: "0xA7421E3f7A1885813B1C9749Cecae48745992A78",
  },

  launchpad: {
    56: "0xA41a3Fc92E383289Be7b637C2c3e8f765642ab53",
    97: "0x40afe949F982c0Ac43f0E84c6482aBFeE34368D4",
  },
  launchpadV2: {
    56: "0xA41a3Fc92E383289Be7b637C2c3e8f765642ab53",
    97: "0xF749Ab9f2565b55dd758939739556bA8C6d8A6d2",
  },
};
