import { Progress } from "antd";
import React from "react";
import styled from "styled-components";
import { numberWithCommas } from "utils/helpers";
import Social from "../../../components/Social";

function ItemProject({ project, launchpad }) {
  const [tokenPrice] = project?.data?.token_price || [];
  const [total] = project?.data?.total || [];
  const isPublic = project?.is_public_for_everyone;
  const totalAmount = isPublic
    ? launchpad?.releaseAmount
    : launchpad?.totalWhitelistAmount + launchpad?.releaseAmount;
  const remain = totalAmount - launchpad?.totalAmount;
  const percent = Math.ceil((remain / totalAmount) * 100);
  return (
    <ItemProjectStyled>
      <Item>
        <figure className="figure__img">
          <img src={project?.data?.project_banner?.url} alt="" />
        </figure>

        <div className="content">
          <Social socialMedias={project?.data?.social_medias} />
          {/* <div className="content__progress">
            <div className="content__progress__wrapper">
              <div className="content__progress__info">
                <p>Token remaining</p>
                <p>
                  {numberWithCommas(remain || 0)} /{" "}
                  {numberWithCommas(totalAmount || 0)}
                </p>
              </div>
              <Progress status="normal" percent={percent} showInfo={false} />
            </div>
          </div> */}
          <div className="content__number">
            <div className="content__number-item">
              <span>Token price</span>
              <h3>
                {tokenPrice?.value} {tokenPrice?.unit}
              </h3>
            </div>

            <div className="content__number-item">
              <span>Total {total?.unit}</span>
              <h3>
                {numberWithCommas(total?.value)} {total?.unit}
              </h3>
            </div>

            <div className="content__number-item">
              <span>Hard Cap</span>
              <h3>
                {numberWithCommas(Math.round(total?.value * tokenPrice?.value))}{" "}
                {tokenPrice?.unit}
              </h3>
            </div>

            <div className="content__number-item">
              <span>Type</span>
              <h3>{project?.data?.type_vesting}</h3>
            </div>
          </div>
        </div>
      </Item>
    </ItemProjectStyled>
  );
}

const ItemProjectStyled = styled.div``;

const Item = styled.div`
  background: #22376f;
  border-radius: 25px;
  overflow: hidden;

  .figure__img {
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    max-height: 275px;
    overflow: hidden;
    object-fit: cover;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .content {
    &__progress {
      padding: 0 32px 32px;
      &__wrapper {
        position: relative;
        padding-top: 32px;
      }
      &__info {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: space-between;
        top: 0;
        left: 0;
        width: 100%;
        font-size: 1.3rem;
        color: white;
        p:first-child {
          color: #7ed6ff;
        }
      }
    }
    > div {
      &:first-of-type {
        margin: 27px 0;
        padding-left: 32px;
      }
    }

    &__number {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;
      padding: 0 32px 32px;
    }

    &__number-item {
      &:nth-child(2n) {
        text-align: right;
      }

      span {
        color: #7ed6ff;
        font-size: 1.25rem;
        @media (max-width: 768px) {
          font-size: 16px;
        }
      }

      h3 {
        color: #ffffff;
        font-size: 1.63rem;
        @media (max-width: 768px) {
          font-size: 18px;
        }
      }
    }
  }
`;
export default ItemProject;
