import { useSelector } from "react-redux";
import { State } from "./types";

export const useUserTokensInfo = () => {
  const userTokensInfo = useSelector(
    (state: State) => state.staking.userData.userTokenData
  );

  return userTokensInfo;
};
