import { ChainId } from "@diviner-protocol/v2-sdk";
import addresses from "config/constants/contracts";
import tokens from "config/constants/tokens";
import { Address } from "config/constants/types";
import { getChainId } from "./web3React";

// TODO : Improve
export const getAddress = (address: Address, chainId?: ChainId): string => {
  const tempChainId = getChainId();
  // const mainNetChainId = 56
  const envChainId = process.env.REACT_APP_CHAIN_ID;
  return address[envChainId];
};

export const getCakeAddress = (chainId?: ChainId) => {
  return getAddress(tokens.cake.address, chainId);
};
export const getPredictionAddress = (chainId?: ChainId): string[] => {
  const tempChainId = getChainId();
  return addresses.prediction[chainId ?? tempChainId];
};

export const getMulticallAddress = (chainId?: ChainId) => {
  return getAddress(addresses.mulltiCall, chainId);
};
export const getWbnbAddress = (chainId?: ChainId) => {
  return getAddress(tokens.wbnb.address, chainId);
};

export const getLaunchpadAddress = (chainId?: ChainId) => {
  return getAddress(addresses.launchpad, chainId);
};
export const getLaunchpadV2Address = (chainId?: ChainId) => {
  return getAddress(addresses.launchpadV2, chainId);
};

export const getMarketplaceAddress = (chainId?: ChainId) => {
  return getAddress(addresses.market, chainId);
};
export const getTokenTransferProxyAddress = (chainId?: ChainId) => {
  return getAddress(addresses.tokenTransferProxy, chainId);
};
export const getNFTsTransferProxyAddress = (chainId?: ChainId) => {
  return getAddress(addresses.nftsTransferProxy, chainId);
};

export const getCharacterAddress = (chainId?: ChainId) => {
  return getAddress(addresses.character, chainId);
};
export const getFakeCharacterAddress = (chainId?: ChainId) => {
  return getAddress(addresses.fakeCharacter, chainId);
};

export const getDptAddress = (chainId?: ChainId) => {
  return getAddress(tokens.dpt.address, chainId);
};

export const getDgtAddress = (chainId?: ChainId) => {
  return getAddress(tokens.dgt.address, chainId);
};
export const getBusdAddress = (chainId?: ChainId) => {
  return getAddress(tokens.busd.address, chainId);
};

export const getChestMarketAddress = (chainId?: ChainId) => {
  return getAddress(addresses.buyChest, chainId);
};

export const getGameAddress = (chainId?: ChainId) => {
  return getAddress(addresses.game, chainId);
};

export const getEquipmentAddress = (chainId?: ChainId) => {
  return getAddress(addresses.equipment, chainId);
};

export const getGameWikiAddress = (chainId?: ChainId) => {
  return getAddress(addresses.gameWiki, chainId);
};

export const getOracleAddress = (chainId?: ChainId) => {
  return getAddress(addresses.priceOracle, chainId);
};

export const getFaucetAddress = (chainId?: ChainId) => {
  return getAddress(addresses.faucet, chainId);
};

export const getStakingAddress = (chainId?: ChainId) => {
  return getAddress(addresses.staking, chainId);
};
