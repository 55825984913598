import { LAUNCHPAD_SLICE_NAME } from "./index";

interface ACTION {
  [key: string]: string;
}
export const LAUNCHPAD_ACTION: ACTION = {
  GET_POOL: `GET_POOL`,
  GET_POOL_USER: `GET_POOL_USER`,
  BUY_TRIBES_USER: `BUY_TRIBES_USER`,
  GET_USER_LAUNCHPAD_TOKEN_INFO: `BUY_TRIBES_USER`,
  GET_USER_CLAIM_AMOUNT: `GET_USER_CLAIM_AMOUNT`,
  GET_USER_HISTORY: `GET_USER_HISTORY`,
};
Object.keys(LAUNCHPAD_ACTION).forEach((key) => {
  LAUNCHPAD_ACTION[key] = `${LAUNCHPAD_SLICE_NAME}/${LAUNCHPAD_ACTION[key]}`;
});

export const ITEMS_PER_PAGE = 15;
