import React from "react";
import styled from "styled-components";
import { Steps } from "antd";
import moment from "moment";
const { Step } = Steps;

function IdoProcess({ project }) {
  const renderSteps = () => {
    return project?.data?.process?.map((item) => (
      <Step
        className="step__process"
        title={item?.label}
        description={item?.date ? moment(item?.date).format("DD/MM") : "TBD"}
      />
    ));
  };
  // const reverseProcess = [...(project?.data?.process || [])]?.reverse();
  // const inprogressStep = reverseProcess?.findIndex(
  //   (item) => new Date(item?.date).getTime() - Date.now() < 0
  // );

  // const progress =
  //   inprogressStep === 0
  //     ? project?.data?.process?.length
  //     : project?.data?.process?.length - inprogressStep - 1;
  const inprogressStep = project?.data?.process?.findIndex(
    (item) => new Date(item?.date).getTime() - Date.now() > 0
  );
  const progress =
    inprogressStep > -1 ? inprogressStep - 1 : project?.data?.process?.length;
  return (
    <BoxProcess>
      <h2>{project?.data?.ido_text || "IDO Process"}</h2>

      <div className="process__item desktop">
        <Steps current={progress} percent={100}>
          {renderSteps()}
        </Steps>
      </div>

      <div className="process__item mobile">
        <Steps direction="vertical" current={progress}>
          {renderSteps()}
        </Steps>
      </div>
    </BoxProcess>
  );
}

const BoxProcess = styled.div`
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    #2b458b;
  border-radius: 25px;
  margin-bottom: 24px;
  height: 250px;

  @media (max-width: 768px) {
    height: auto;
  }

  .step__process {
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .ant-steps-item-icon {
      margin: 0px;
    }
    .ant-steps-item-title {
      padding: 0px;
    }
  }

  h2 {
    color: #ffffff;
    background: #35beff;
    padding: 20px 30px;
    font-size: 1.8rem;
    font-weight: 600;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    @media (max-width: 768px) {
      font-size: 25px;
      padding: 10px 25px;
    }
  }

  .process__item {
    padding: 35px 42px;
    position: relative;
    margin: 0px 32px;
    @media (max-width: 768px) {
      padding: 35px 0px;
    }
    &.desktop {
      display: none;

      @media (min-width: 767px) {
        display: block;
      }
    }

    &.mobile {
      display: block;
      .ant-steps-item-icon {
        z-index: 100;
      }

      .ant-steps-item-container {
        padding-top: 0px;
      }

      .ant-steps-item-tail {
        padding: 0px;
      }

      .ant-steps-item-title {
        padding: 0px;
        transform: translate(0px, 0px);
        left: 0%;
      }

      @media (min-width: 767px) {
        display: none;
      }
    }

    .ant-steps {
      .ant-steps-item-icon {
        font-weight: 700;
        font-family: "Chalkboard SE";
        .ant-steps-icon {
          top: -2.5px;
        }
      }
      .ant-steps-item-content {
        @media (max-width: 768px) {
          padding-left: 10px;
        }
        .ant-steps-item-title {
          left: 50%;
          transform: translate(-50%, 0px);
          @media (max-width: 1024px) {
            font-size: 18px;
          }
          @media (max-width: 768px) {
            font-size: 13px;
          }
          @media (max-width: 500px) {
            transform: unset;
            left: unset;
          }
        }
      }
      &-item {
        overflow: visible !important;

        /* @media (min-width: 767px) {
          width: 200px;
        } */

        &:after {
          content: "";
          width: 100%;
          border: 1px dashed rgba(255, 255, 255, 0.24);
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);

          @media (max-width: 767px) {
            display: none;
          }
        }
      }

      &-item-container {
        position: relative;
        display: flex;
        justify-content: space-between;
        z-index: 2;
        padding: 15px 0px;
      }

      &-item-title,
      &-item-description {
        color: #ffffff !important;
      }

      &-item-title {
        position: unset;
        bottom: unset;
        left: unset;
        transform: unset;
        font-size: 1.25rem;
        font-weight: 600;

        @media (min-width: 767px) {
          position: absolute;
          bottom: -33px;
          transform: translateX(-50%);
          font-size: 1.25rem;
          font-weight: 600;
        }

        &:after {
          display: none;
        }
      }

      &-item-description {
        position: unset;
        top: unset;
        left: unset;
        transform: unset;
        font-size: 1rem;
        font-weight: 600;

        @media (min-width: 767px) {
          position: absolute;
          top: -26px;
          left: 50%;
          transform: translateX(-50%);
          font-size: 1rem;
          font-weight: 600;
        }
      }
    }
  }
`;
export default IdoProcess;
