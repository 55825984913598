import { DPT_BNB_PRICE, DPT_PRICE } from "config/constants/price";
import erc20ABI from "config/abi/erc20.json";
import stakingAbi from "config/abi/Staking.json";
import { stakingConfig } from "config/constants/staking";
import tokens from "config/constants/tokens";
import { ethers } from "ethers";
import { getAddress, getStakingAddress } from "utils/addressHelpers";
import { getPoolApy } from "utils/apy";
import { getStakingContract } from "utils/contractHelpers";
import multicall from "utils/multicall";
import { UserPoolData } from "../types";

export const fetchGameUserTokenInfo = async (account: string) => {
  const calls = [];
  const dptToken = getAddress(tokens.dpt.address);
  const lpToken = getAddress(tokens.dptBnb.address);
  const busdToken = getAddress(tokens.busd.address);

  calls.push({ address: dptToken, name: "balanceOf", params: [account] });
  calls.push({ address: lpToken, name: "balanceOf", params: [account] });
  calls.push({ address: busdToken, name: "balanceOf", params: [account] });
  calls.push({
    address: dptToken,
    name: "allowance",
    params: [account, getStakingAddress()],
  });
  calls.push({
    address: lpToken,
    name: "allowance",
    params: [account, getStakingAddress()],
  });
  calls.push({
    address: busdToken,
    name: "allowance",
    params: [account, getStakingAddress()],
  });
  try {
    const [
      dptBalance,
      lpBalance,
      busdBalance,
      dptAllowance,
      lpAllowance,
      busdAllowance,
    ] = await multicall(erc20ABI, calls, {
      requireSuccess: false,
    });

    return {
      dptBalance: dptBalance.toString(),
      dptAllowance: dptAllowance.toString(),
      lpBalance: lpBalance.toString(),
      lpAllowance: lpAllowance.toString(),
      busdBalance: busdBalance.toString(),
      busdAllowance: busdAllowance.toString(),
    };
  } catch (error) {
    console.log("error fetchGameUserTokenInfo", error);
    return {
      dptBalance: "0",
      dptAllowance: "0",
      lpBalance: "0",
      lpAllowance: "0",
      busdAllowance: "0",
      busdBalance: "0",
    };
  }
};

export const fetchUserPoolData = async (
  account: string
): Promise<UserPoolData[]> => {
  const calls = [];
  for (let i = 0; i <= 5; i++) {
    calls.push({
      address: getStakingAddress(),
      name: "users",
      params: [i, account],
    });
  }

  try {
    const results = await multicall(stakingAbi, calls, {
      requireSuccess: false,
    });

    return results.map((result) => {
      return {
        amountStaked: result.amount.toString(),
        firstTimeDeposit: result.firstTimeDeposit.toNumber(),
      };
    });
  } catch (error) {
    console.log("error fetchUserPoolData", error);
    return [];
  }
};

export const fetchPoolDataWithPoolId = async (
  account: string,
  poolId: number
) => {
  try {
    const stakingContract = getStakingContract();
    const getUserPoolInfoPromise = stakingContract.users(poolId, account);
    const getPoolInfoPromise = stakingContract.pools(poolId);
    const pendingRewardPromise = stakingContract.pendingReward(poolId, account);

    const [userPoolInfo, poolInfo, pendingReward] = await Promise.all([
      getUserPoolInfoPromise,
      getPoolInfoPromise,
      pendingRewardPromise,
    ]);

    const apy = getPoolApy(
      poolId < 3 ? DPT_PRICE : DPT_BNB_PRICE,
      DPT_PRICE,
      parseFloat(ethers.utils.formatEther(poolInfo.totalAmount.toString())),
      parseFloat(ethers.utils.formatEther(poolInfo.rewardPerBlock.toString()))
    );

    return {
      poolId,
      userPoolInfo: {
        amountStaked: userPoolInfo.amount.toString(),
        firstTimeDeposit: userPoolInfo.firstTimeDeposit.toNumber(),
        pendingReward: pendingReward.toString(),
      },
      totalAmount: poolInfo.totalAmount.toString(),
      apy: apy ? apy.toString() : "0",
    };
  } catch (error) {
    console.log("error fetchPoolDataWithPoolId", error);
    return [];
  }
};

export const fetchPendingReward = async (account: string) => {
  try {
    const calls = [];
    for (let i = 0; i <= 5; i++) {
      calls.push({
        address: getStakingAddress(),
        name: "pendingReward",
        params: [i, account],
      });
    }

    const results = await multicall(stakingAbi, calls, {
      requireSuccess: false,
    });
    return results.map((result) => result.toString());
  } catch (error) {
    console.log("error fetchPendingReward", error);
  }
};

export const fetchPendingRewardWithPoolId = async (
  account: string,
  poolId: string
) => {
  try {
    const stakingContract = getStakingContract();
    return (await stakingContract.pendingInterest(poolId, account)).toString();
  } catch (error) {
    console.log("error fetchPendingRewardWithPoolId", error);
  }
};

export const fetchTrackedAmount = async (account: string) => {
  try {
    const stakingContract = getStakingContract();
    const trackedAmount = await stakingContract.getTickForUser(account);

    return trackedAmount;
  } catch (error) {
    console.log("fetchTrackedAmount", error);
  }
};

export const fetchPoolStakeAmount = async () => {
  const stakingContract = getStakingContract();
  const round = await stakingContract.round();
  const poolStakedAmount = await stakingContract.poolStakedAmount(
    Number(round.toString())
  );
  return poolStakedAmount;
};
