import GlobalModal from "components/static/GlobalModal";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Button, Input, Spin } from "antd";
import { useAppDispatch } from "state";
import { useHistory } from "react-router-dom";
import { numberWithCommas } from "utils/helpers";
import moment from "moment";
import { useWeb3React } from "@web3-react/core";
import { addCommas } from "utils/formatNumber";
import { usePoolsSelector, useUserDataSelector } from "state/staking/selector";
import Countdown from "components/Countdown";
import ConnectWalletModal from "../../../components/Modal/ConnectWalletModal";

function Purchase({
  userTokenData,
  handleSubmit,
  user,
  project,
  launchpad,
  isLoading,
  handleClaim,
  handleRefund,
}) {
  const { push } = useHistory();
  const { account } = useWeb3React();
  const [amount, setAmount] = useState({
    buy: "",
    get: "",
  });
  const calculateReleaseRate =
    (isToken = true) =>
    (value, fixed = null) => {
      const updatedValue = isToken
        ? (+value * +launchpad?.releaseRate) / 10000
        : (+value / +launchpad?.releaseRate) * 10000;
      if (fixed) return updatedValue.toFixed(fixed);
      return updatedValue;
    };
  const mapAmount = (name: string, value: string) => {
    if (name === "get") {
      return {
        [name]: value,
        buy: calculateReleaseRate(true)(value, 4),
      };
    }
    return {
      [name]: value,
      get: calculateReleaseRate(false)(value, 2),
    };
  };
  const handleChange = (e) => {
    const { value, name } = e.target;
    if (!Number.isNaN(+value)) {
      let updatedAmount: any = {
        buy: maxToken,
        get: user?.maxAmountUserCanBuy,
      };
      if (
        (name === "get" && +value <= +user?.maxAmountUserCanBuy) ||
        (name === "buy" && +value <= +maxToken)
      ) {
        updatedAmount = mapAmount(name, value);
      }
      setAmount(updatedAmount);
    }
  };
  const handleRedirectStaking = () => {
    push("/staking");
  };
  // useEffect(() => {
  //   const startTime = launchpad?.startTime * 1000 ?? Date.now();
  //   const currentTime = Date.now();
  //   const endTime = launchpad?.endTime * 1000 ?? Date.now();
  //   if (currentTime >= endTime + (launchpad?.delayTime || 0) * 1000) {
  //   }
  // }, [launchpad, account]);

  const handleBuy = () => {
    handleSubmit(amount);
    setAmount({
      buy: "",
      get: "",
    });
  };
  const [tokenPrice] = project?.data?.token_price || [];
  const [total] = project?.data?.total || [];
  const mapTokenAmount = calculateReleaseRate(true);
  const maxToken = mapTokenAmount(user?.maxAmountUserCanBuy, 4);
  const isApproved = userTokenData?.busdAllowance !== "0";
  const calculateVestingDate = () => {
    let times = 0;
    const today = Date.now();
    let nextVestingDate =
      (+launchpad?.endTime + +launchpad?.delayTime + +launchpad?.lockingTime) *
      1000;
    while (today > nextVestingDate && times < 10) {
      times++;
      nextVestingDate += +launchpad?.lockingTime * 1000;
    }
    return [times, nextVestingDate];
  };
  const renderContent = () => {
    const startTime = launchpad?.startTime * 1000 ?? Date.now();
    const currentTime = Date.now();
    const endTime = launchpad?.endTime * 1000 ?? Date.now();
    if (!account)
      return (
        <div className="purchase__content purchase__content--not-connect">
          <ConnectWalletButton
            type="submit"
            onClick={() => {
              GlobalModal.show(
                <ConnectWalletModal onClose={GlobalModal.hide} />
              );
            }}
          >
            <p>Connect wallet</p>
            <img src="./images/icon-wallet.svg" alt="connect wallet" />
          </ConnectWalletButton>
        </div>
      );

    if (currentTime >= startTime && currentTime <= endTime)
      return (
        <div className="purchase__content">
          <div className="purchase__content-wrap">
            <div className="purchase__content-wrap-item">
              <span>Max Purchase:</span>
              <p>
                {numberWithCommas(user?.maxAmountUserCanBuy || 0)} {total?.unit}{" "}
                - {maxToken || 0} {tokenPrice?.unit}
              </p>
            </div>
            <div className="purchase__content-wrap-item">
              <span>Bought:</span>
              <p>
                {numberWithCommas(user?.amount || 0)} {total?.unit}
              </p>
            </div>
          </div>

          {isApproved && (
            <div className="purchase__content-sale">
              <div className="purchase__content-sale__flex">
                <span className="title">Buy</span>
                <Input
                  name="buy"
                  onChange={handleChange}
                  value={amount?.buy}
                  defaultValue="5000"
                  suffix="BUSD"
                />
              </div>

              <div className="purchase__content-sale__flex">
                <span className="title">Get</span>
                <Input
                  name="get"
                  onChange={handleChange}
                  value={amount?.get}
                  defaultValue="2000"
                  suffix={total?.unit}
                />
              </div>
            </div>
          )}

          <div className="purchase__content-sale__button">
            <Button disabled={isLoading} onClick={handleBuy}>
              {isLoading ? <Spin /> : isApproved ? "Confirm" : `Approve BUSD`}
            </Button>
          </div>
        </div>
      );

    if (currentTime >= endTime) {
      const refundTime =
        endTime +
        ((+launchpad?.delayTime || 0) + (+launchpad?.refundTime || 0)) * 1000;
      const afterDelayTime = endTime + (+launchpad?.delayTime || 0) * 1000;
      const isAfter = Date.now() > afterDelayTime;
      const [times, nextVestingDate] = calculateVestingDate();
      return (
        <div className="purchase__content distribute">
          <div className="purchase__content-wrap">
            <div className="purchase__content-wrap-item">
              <span>Bought:</span>
              <p>
                {" "}
                {numberWithCommas(user?.amount || 0)} {total?.unit} -{" "}
                {mapTokenAmount(user?.amount, 4) || 0} {tokenPrice?.unit}
              </p>
            </div>

            {times < 10 && (
              <div className="purchase__content-wrap-item">
                <span>Next Vesting Date:</span>
                <p>{moment(nextVestingDate).format("DD/MM hh:mm a")}</p>
              </div>
            )}

            <div className="purchase__content-wrap-item">
              <span>Available {total?.unit} to claim:</span>
              <p>
                {" "}
                {numberWithCommas(user?.claimToken) || 0} {total?.unit}
              </p>
            </div>
          </div>

          <div className="purchase__content-wrap-button">
            {+launchpad?.refundTime !== 0 &&
              Date.now() - refundTime < 0 &&
              user?.claimedMark === "0" && (
                <Button
                  className={`button-refund ${isAfter ? "after" : ""}`}
                  onClick={handleRefund}
                  type="primary"
                  disabled={+user?.amount === 0 || isLoading || !isAfter}
                >
                  {isLoading ? (
                    <Spin />
                  ) : (
                    <>
                      Refund <br />
                      {isAfter && <Countdown date={refundTime} />}
                    </>
                  )}
                </Button>
              )}
            <Button
              type="primary"
              onClick={handleClaim}
              disabled={+user?.claimToken === 0 || isLoading || !isAfter}
            >
              {isLoading ? <Spin /> : "Claim"}
            </Button>
          </div>
        </div>
      );
    }
    return (
      <div
        className={`purchase__content ${
          project?.data?.is_public_for_everyone ? "public" : ""
        }`}
      >
        <h3>{project?.data?.purchase_empty_text || "IDO not active"}</h3>

        {!project?.data?.is_public_for_everyone && (
          <Button type="primary" onClick={handleRedirectStaking}>
            Stake Min. 50k Ticket to get allocation
          </Button>
        )}
      </div>
    );
  };
  const Content = renderContent();
  return (
    <PurchaseStyled>
      {Content && <h2>Purchase</h2>}
      {Content}
    </PurchaseStyled>
  );
}

const ConnectWalletButton = styled.button`
  margin: 0 auto;
  background: url("./images/market/header-button.png") no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
  width: 100%;
  max-width: 160px;
  height: 46px;
  line-height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(1);
  transition: 0.5s ease-in-out;
  border: none;

  @media (max-width: 767px) {
    background: unset;
    position: absolute;
    top: 11px;
    right: -61px;
  }

  &:hover {
    transform: scale(1.1);
    transition: 0.5s ease-in-out;
  }

  p {
    color: #fff;
    font-size: 16px;
    margin-bottom: 0;
    display: none;
    white-space: nowrap;

    @media (min-width: 767px) {
      display: block;
    }
  }

  img {
    display: block;
    max-width: 30px;

    @media (min-width: 767px) {
      display: none;
    }
  }
`;

const PurchaseStyled = styled.div`
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    #2b458b;
  border-radius: 25px;
  /* height: 275px; */

  @media (max-width: 768px) {
    height: auto;
  }

  h2 {
    color: #ffffff;
    background: #35beff;
    padding: 20px 25px;
    font-size: 1.8rem;
    font-weight: 600;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    @media (max-width: 768px) {
      font-size: 25px;
      padding: 10px 25px;
    }
  }

  .purchase__content {
    padding: 20px 25px;
    text-align: center;
    &.public {
      h3 {
        margin-top: 30px;
      }
    }
    h3 {
      color: rgba(255, 255, 255, 0.5);
      margin-bottom: 30px;
      font-size: 2rem;
      font-weight: 600;
    }

    button {
      background: #55b3f8;
      border-radius: 50px;
      width: 100%;
      max-width: 400px;
      line-height: unset;
      font-size: 1.25rem;
      font-weight: 600;
      height: 56px;
      position: relative;

      @media (max-width: 1024px) {
        max-width: 280px;
      }

      @media (max-width: 768px) {
        font-size: 1rem;
      }

      &[disabled] {
        background-color: #b5b5b5 !important;
        color: rgba(255, 255, 255, 0.65) !important;
      }

      &.button-refund {
        &.after {
          span {
            &:first-of-type {
              margin-top: -10px;
            }
          }
        }
        span {
          display: block;

          &:nth-child(2) {
            font-weight: 300;
            font-weight: 300;
            position: absolute;
            bottom: 6px;
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }
    }

    &--not-connect {
      button {
        @media (max-width: 768px) {
          font-size: 1rem;
          max-width: 70px;
          background: #fff;
          border-radius: 50%;
          height: 70px;
          top: unset;
          right: unset;
        }
      }
    }

    &-wrap {
      display: flex;
      flex-direction: column;
    }

    &-wrap-button {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @media (max-width: 768px) {
        display: block;
        .ant-btn.ant-btn-primary {
          margin-top: 15px;
        }
        button.ant-btn.ant-btn-primary.button-refund {
          margin-top: 0px;
        }
      }
    }

    &-wrap-item {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: left;
      margin-bottom: 8px;

      span {
        color: #72c2ed;
        font-size: 1.13rem;
        font-weight: 400;
        margin-bottom: 15px;
        padding-right: 15px;
        @media (max-width: 768px) {
          font-size: 1rem;
        }
      }

      p {
        color: #ffffff;
        font-size: 1.25rem;
        font-weight: 600;
        @media (max-width: 768px) {
          font-size: 14px;
        }
      }
    }

    &-sale {
      display: flex;
      justify-content: space-between;
      @media (max-width: 768px) {
        display: block;
      }
    }

    &-sale__flex {
      display: flex;
      justify-content: space-between;
      justify-content: center;
      align-items: center;
      @media (max-width: 768px) {
        margin-top: 20px;
        &:nth-child(1) {
          margin-top: 0px;
        }
      }

      .ant-input-affix-wrapper {
        border-radius: 22px;
        padding: 0px 11px;
        width: 205px;
      }

      span {
        &.title {
          color: #72c2ed;
          font-weight: 300;
          font-size: 1.25rem;
          display: block;
          margin-right: 10px;
        }

        input,
        .ant-input-suffix {
          font-weight: 500;
          font-size: 1rem;
        }

        input {
          color: #7d7f81;
          height: 33px;
        }

        .ant-input-suffix {
          color: #1796ff;
        }
      }
    }

    &-sale__button {
      padding-top: 25px;
      button {
        border: 1px solid #ffffff;
        color: #ffffff;
        max-width: 450px;
        height: 40px;
        background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.2),
            rgba(0, 0, 0, 0.2)
          ),
          #2b458b;
      }
    }

    &.distribute {
      padding: 15px 25px;
      .purchase__content-wrap-item {
        justify-content: space-between;
        span {
          margin-bottom: 0px;
        }
      }
      .purchase__content-wrap-button {
        padding-top: 10px;
        justify-content: center;
        gap: 16px;
      }
    }
  }
`;
export default Purchase;
