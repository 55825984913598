import { useSelector } from "react-redux";
import { State } from "../types";

export const useUserDataSelector = () => {
  const userData = useSelector((state: State) => state.staking.userData);
  return userData;
};

export const useUserTokensDataSelector = () => {
  const userTokensInfo = useSelector(
    (state: State) => state.staking.userData.userTokenData
  );

  return userTokensInfo;
};

export const useUserPoolDataSelector = () => {
  const userPoolsData = useSelector(
    (state: State) => state.staking.userData.userPoolData
  );
  return userPoolsData;
};

export const usePoolsSelector = () => {
  const pools = useSelector((state: State) => state.staking.poolInfo);
  return pools;
};
