import "antd/dist/antd.css";
import { PrismicProvider } from "@prismicio/react"
import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import Providers from "./Providers"
import { client } from "./config/prismic"

if ("ethereum" in window) {
  (window.ethereum as any).autoRefreshOnNetworkChange = false;
}

window.addEventListener("error", () => {
  localStorage?.removeItem("redux_localstorage_simple_lists");
});

ReactDOM.render(
  <React.StrictMode>
    <Providers>
      <PrismicProvider client={client}>
        <App />
      </PrismicProvider>
    </Providers>
  </React.StrictMode>,
  document.getElementById("root")
);
