import { configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { load } from "redux-localstorage-simple";
import application from "./application/reducer";
import staking from "./staking";
import launchpad from "./launchpad";

import transactions from "./transactions/reducer";
import lists from "./lists/reducer";

type MergedState = {
  user: {
    [key: string]: any;
  };
  transactions: {
    [key: string]: any;
  };
};
const PERSISTED_KEYS: string[] = ["user", "transactions"];
const loadedState = load({ states: PERSISTED_KEYS }) as MergedState;
if (loadedState.user) {
  // loadedState.user.userDarkMode = getThemeCache()
}
const store = configureStore({
  devTools: process.env.NODE_ENV !== "production",
  reducer: {
    transactions,
    application,
    lists,
    staking,
    launchpad
  },
  // middleware: [...(getDefaultMiddleware({ thunk: false }) as any), save({ states: PERSISTED_KEYS })],
  // preloadedState: loadedState,
});

/**
 * @see https://redux-toolkit.js.org/usage/usage-with-typescript#getting-the-dispatch-type
 */
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export type AppState = ReturnType<typeof store.getState>;

export default store;
