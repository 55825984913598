import React, { useEffect, useState } from "react";
import Countdown from "components/Countdown";
import { useHistory } from "react-router-dom";
import ReactDOM from "react-dom";
import styled from "styled-components";
import { Tabs, Button, Tooltip } from "antd";
import Social from "../../../components/Social";

const { TabPane } = Tabs;

const TabsProject = ({ projects }) => {
  const [activeTab, setActiveTab] = useState(() => {
    return projects?.filter((item) => item?.data?.project_status === "active")
      .length > 0
      ? "active"
      : "upcoming";
  });
  const { push } = useHistory();
  const mappedData =
    projects?.filter((item) => item?.data?.project_status === activeTab) || [];

  const handleChangeTab = (key) => {
    setActiveTab(key);
  };
  const handleClickProject = (url: string) => () => {
    push(url);
  };
  const tabs = [
    {
      value: "active",
      label: "Active IDO",
    },
    {
      value: "upcoming",
      label: "Upcoming IDO",
    },
    {
      value: "finish",
      label: "Finish IDO",
    },
    {
      value: "joined",
      label: "Joined IDO",
    },
  ];
  const renderProjects = (items) =>
    items.map((item, idx) => {
      const isSaleTime =
        Date.now() - new Date(item?.data?.sale_in).getTime() < 0;

      return (
        <ItemIDO key={`${item?.uid}-${idx}`}>
          <div className="thumb__img">
            <img
              src={item?.data?.project_img?.url}
              alt={item?.data?.project_name}
            />
          </div>

          <div className="content__ido">
            <h3>{item?.data?.project_name}</h3>

            <Tooltip title={item?.data?.project_description?.[0]?.text}>
              <p className="description">
                {item?.data?.project_description?.[0]?.text}
              </p>
            </Tooltip>

            <Social socialMedias={item?.data?.social_medias} />
            <p className="countdown">
              {isSaleTime ? (
                <>
                  <span>Sale starts in:</span>{" "}
                  <Countdown date={item?.data?.sale_in} />
                </>
              ) : (
                <>
                  <span>Sale ends in:</span>
                  <Countdown date={item?.data?.end_sale} />
                </>
              )}
            </p>
            <Button
              className="button__ido"
              type="primary"
              onClick={handleClickProject(`/project/${item?.uid}`)}
            >
              Detail
            </Button>
          </div>
        </ItemIDO>
      );
    });
  const renderTabs = () =>
    tabs.map((tab, idx) => (
      <TabPane tab={tab.label} key={tab.value}>
        <BoxITemIDO>{renderProjects(mappedData)}</BoxITemIDO>
      </TabPane>
    ));
  return (
    <BoxTabs>
      <Title>
        <h2>Project</h2>
        <p>The first launchpad for Diviner Protocol</p>
      </Title>

      <Tabs activeKey={activeTab} onChange={handleChangeTab}>
        {/* <TabPane tab="Active IDO" key="active">
          <BoxITemIDO>{renderProjects(mappedData)}</BoxITemIDO>
        </TabPane>

        <TabPane tab="Upcoming IDO" key="upcoming">
          {renderProjects(mappedData)}
        </TabPane>

        <TabPane tab="Finish IDO" key="finish">
          {renderProjects(mappedData)}
        </TabPane>

        <TabPane tab="Joined IDO" key="4"></TabPane> */}
        {renderTabs()}
      </Tabs>
    </BoxTabs>
  );
};

const Title = styled.div`
  text-align: center;
  color: #fff;
  padding: 40px 0;
  h2 {
    font-size: 3rem;
    color: #fff;
    font-weight: 900;
  }
  p {
    font-size: 1.2rem;
  }
`;

const BoxTabs = styled.div`
  margin-bottom: 160px;
  .title {
  }
  .ant-tabs-tab {
    font-size: 1.6rem;
    font-weight: 500;
    color: #ffffff;
    &.ant-tabs-tab-active {
      font-weight: 600;
    }
  }
  .ant-tabs-nav {
    margin-bottom: 48px;
  }
`;

const BoxITemIDO = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 60px;

  @media (min-width: 767px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const ItemIDO = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
  background: #4c5e9e;
  border-radius: 12px;

  @media (min-width: 767px) {
    grid-template-columns: 328px 1fr;
  }

  .thumb__img {
    max-width: 330px;
    overflow: hidden;
    img {
      width: 100%;
    }
  }

  .content__ido {
    color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 10px;
    .description {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3; /* number of lines to show */
      line-clamp: 3;
      -webkit-box-orient: vertical;
    }
    .countdown {
      display: flex;
      gap: 8px;
      align-items: center;
      span {
        color: #ffe34e;
      }
    }
    h3 {
      font-size: 2rem;
      font-weight: 600;
      line-height: 36px;
      color: #ffffff;
      margin-bottom: 24px;
    }

    p {
      font-size: 1rem;
      font-weight: 300;
      line-height: 20px;
      margin-bottom: 24px;
    }
  }

  .button__ido {
    background: #55b3f8;
    border-radius: 50px;
  }
`;
export default TabsProject;
