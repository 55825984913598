import { createSlice } from "@reduxjs/toolkit";
import {
  isFulfilledAction,
  isPendingAction,
  isRejectedAction,
} from "../../utils/helpers";
export interface IPagination {
  currentPage: number;
  totalPage: number;
}
export interface ILaunchpadHistory {
  transactions: ILaunchpadTransaction[];
  pagination: IPagination;
}
export interface ILaunchpadTransaction {
  poolId: string;
}
export interface ILaunchpadSliceState {
  pool: IPoolValues;
  user: IPoolUserValues;
  isLoading: boolean;
  rebound: boolean;
  userTokenData: IUserTokenData;
  history: ILaunchpadHistory;
}
export interface IUserTokenData {
  busdAllowance: string;
  busdBalance: string;
}
export interface IPoolValues {
  totalAmount: number;
}
export interface IPoolUserValues {
  totalAmount: number;
}

export const LAUNCHPAD_SLICE_NAME = "launchpad";
const initialState = {
  pool: {},
  user: {},
  history: {
    pagination: {
      currentPage: 0,
      totalPage: 0,
    },
    transactions: [],
  },
  isLoading: false,
  rebound: true,
  userTokenData: {},
} as ILaunchpadSliceState;

export const launchpadSlice = createSlice({
  name: LAUNCHPAD_SLICE_NAME,
  initialState,
  reducers: {
    setLaunchpadState: (state, action) => {
      Object.keys(action?.payload || {}).forEach((key) => {
        state[key as keyof ILaunchpadSliceState] = action.payload[key];
      });
    },
    setPagination: (state, action) => {
      state.history.pagination.currentPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        isFulfilledAction(`${LAUNCHPAD_SLICE_NAME}/`),
        (state, action) => {
          state.isLoading = false;
          Object.keys(action?.payload || {}).forEach((key) => {
            state[key as keyof ILaunchpadSliceState] = action.payload[key];
          });
        }
      )
      .addMatcher(isPendingAction(`${LAUNCHPAD_SLICE_NAME}/`), (state) => {
        state.isLoading = true;
      })
      .addMatcher(isRejectedAction(`${LAUNCHPAD_SLICE_NAME}/`), (state) => {
        state.isLoading = false;
      });
  },
});

// Selectors
export const getTransactionsSelector = (state: any) =>
  state.launchpad.history.transactions;
export const getHistoryPaginationSelector = (state: any) =>
  state.launchpad.history.pagination;
export const getUserTokenLaunchpadSelector = (state: any) =>
  state.launchpad.userTokenData;
export const getPoolLaunchpadSelector = (state: any) => state.launchpad.project;
export const getPoolUserLaunchpadSelector = (state: any) =>
  state.launchpad.user;
export const getIsLoadingLaunchpadSelector = (state: any) =>
  state.launchpad.isLoading;

export const { setLaunchpadState, setPagination } = launchpadSlice.actions;
export default launchpadSlice.reducer;
