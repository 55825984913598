import { useWeb3React } from "@web3-react/core";
import {
  useAllPrismicDocumentsByType,
  usePrismicDocuments,
} from "@prismicio/react";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "state/";
import { fetchGameUserData, fetchPoolsData } from "state/staking";
import styled from "styled-components";
import { useActiveWeb3React } from "hooks";
import { utils } from "ethers";
import Banner from "./Components/Banner";
import Tabs from "./Components/Tabs";
import { State } from "../../state/types";
import { stake } from "../../utils/callHelpers";

const HomePage: React.FC = () => {
  const { account } = useActiveWeb3React();
  const dispatch = useAppDispatch();
  const poolsInfo = useSelector((state: State) => state.staking.poolInfo);
  const [projects, { state }] = useAllPrismicDocumentsByType("project");
  useEffect(() => {
    dispatch(fetchPoolsData());
    return () => {
      document.querySelector("#root").scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
  }, []);
  useEffect(() => {
    if (account) dispatch(fetchGameUserData(account));
  }, [account]);
  let totalDptStaked = 0;
  let totalDptBnbStaked = 0;
  const tvl = poolsInfo.reduce((acc, cur, index) => {
    let value = parseInt(utils.formatEther(cur.totalAmount));
    if (index < 3) {
      totalDptStaked += value;
      value *= 0.0083;
    } else {
      totalDptBnbStaked += value;
      value *= 3.52;
    }
    return value + acc;
  }, 0);

  const averageAPy =
    poolsInfo.reduce((acc, cur) => {
      return acc + parseInt(cur.apy);
    }, 0) / poolsInfo.length;

  return (
    <HomePageStyled>
      <Banner
        tvl={tvl.toString()}
        totalDptStaked={totalDptStaked.toString()}
        totalDptBnbStaked={totalDptBnbStaked.toString()}
        averageApy={averageAPy.toString()}
      />
      {state === "loaded" && <Tabs projects={projects} />}
    </HomePageStyled>
  );
};

const HomePageStyled = styled.div`
  .slick-track {
    display: flex;
    gap: 30px;
  }
`;

const InformationNumber = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(285px, 1fr));
  gap: 38px;
  margin-bottom: 54px;

  .item {
    border: 3px solid #7ed5ff;
    background: linear-gradient(180deg, #2c8cd1 0%, #297ac7 100%);
    border-radius: 12px;
    padding: 12px 0;
    text-align: center;

    span {
      font-size: 1.5rem;
      color: #ffffff;
    }

    h3 {
      font-size: 1.7rem;
      color: #9afff9;
    }
  }
`;

const ItemUpcomingIDO = styled.div`
  padding: 0 32px 17px;
  background: #2b458b;
  border-radius: 17px;

  figure {
    margin: 0 -32px 31px;
    position: relative;

    img {
      width: 100%;
    }

    span {
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
      background-color: #ffffff;
      color: #18aff7;
      padding: 7px 22px;
      border-top-left-radius: 17px;
      border-top-right-radius: 17px;
      font-size: 1.63rem;
      white-space: nowrap;
      font-weight: 600;
    }
  }

  .content {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
    gap: 30px;
    color: #ffffff;
    margin-bottom: 43px;

    span,
    h3 {
      text-transform: capitalize;
    }

    h3 {
      color: #ffffff;
      font-size: 1.6rem;
    }

    span {
      color: #b5acac;
      font-size: 1.25rem;
    }

    &__left {
      text-align: left;
    }

    &__right {
      text-align: right;
    }
  }
`;

const BoxSocial = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 22px;
  padding: 7px 0;
  margin-top: 15px;
  background: #7ed5ff;
  border-radius: 25px;

  a {
    cursor: pointer;

    img {
      transform: scale(1);
      transition: 0.5s ease-in-out;
    }

    &:hover {
      img {
        transform: scale(1.1);
        transition: 0.5s ease-in-out;
      }
    }
  }
`;
export default HomePage;
