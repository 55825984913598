import { Button } from "antd";
import React from "react";
import styled from "styled-components";

function MyButton({ onClick = null, Name, disabled = false }) {
  return (
    <ButtonStyles>
      <Button disabled={disabled} className="my-button" onClick={onClick}>
        {Name}
      </Button>
    </ButtonStyles>
  );
}

const ButtonStyles = styled.div`
  width: 100%;
  background: unset;
  border: unset;
  /* padding: 0 40px; */

  button[disabled] {
    background-color: transparent !important;
    cursor: not-allowed;
    border: solid 1px #ffffff78 !important;

    &:hover {
      border: solid 1px #ffffff78 !important;
    }
  }

  .my-button {
    border: unset;
    cursor: pointer;
    background: #55B3F8;
    border-radius: 24px;
    width: 100%;
    height: 39px;
    align-items: center;
    display: flex;
    justify-content: center;
    color: #ffffff;
    transition: 0.5s ease-in-out;
    font-size: 20px;
    font-weight: 400;

    &:hover {
      color: #ffffff;
      border: solid 1px #ffffff !important;
    }
  }
`;

export default MyButton;
