import { useWeb3React } from "@web3-react/core";
import { getDefaultProvider, utils } from "ethers";
// import LoadingFullScreen from 'components/Common/LoadingFullScreen'
import GlobalModal from "components/static/GlobalModal";
import { maxNumberAfterDot } from "utils/formatNumber";
// import { useActiveWeb3React } from 'hooks'
import useRefresh from "hooks/useRefresh";
import useWeb3ReactManager from "hooks/useWeb3ReactManager";
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import {
  HashRouter,
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import { useAppDispatch } from "state";
import { useUserTokensInfo } from "state/hook";
import styled from "styled-components";
import Project from "views/Project";
import HomePage from "views/HomePage";
import Header from "components/Header";
import Footer from "components/Footer";
import "./index.scss";
import PageStaking from "views/Staking";
import { simpleRpcProvider } from "utils/providers";

function fakeRequest() {
  return new Promise<void>((resolve) => setTimeout(() => resolve(), 1500));
}

//test
const App: React.FC = () => {
  useEffect(() => {
    console.warn = () => null;
  }, []);
  const { account, chainId } = useWeb3React();
  const [bnbBalance, setBnbBalance] = useState<string>("0");
  // const { bnbBalance, setBnbBalance } = useState<string>(null);
  // console.log('bnbBalance : ', bnbBalance);
  useWeb3ReactManager();

  useEffect(() => {
    if (chainId) {
      window.localStorage.setItem("chainId", chainId.toString());
    }
  }, [chainId]);

  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    fakeRequest().then(() => {
      setLoading(!isLoading);
    });
  }, []);

  const { fastRefresh } = useRefresh();
  const dispatch = useAppDispatch();
  const userTokensInfo = useUserTokensInfo();

  useEffect(() => {
    if (account) {
      simpleRpcProvider
        .getBalance(account)
        .then((value) => setBnbBalance(utils.formatEther(value)));
      // dispatch(fetchUserToken(account));
    }
  }, [fastRefresh, account]);

  return (
    <HashRouter>
      <Header
        bnbBalance={maxNumberAfterDot(bnbBalance, 2)}
        busdBalance={maxNumberAfterDot(
          utils.formatEther(userTokensInfo.busdBalance),
          2
        )}
        dptBalance={maxNumberAfterDot(
          utils.formatEther(userTokensInfo.dptBalance),
          2
        )}
      />
      {/* {(isLoading || isMobile) && <LoadingFullScreen />} */}
      <FullScreenStyled>
        <Space>
          <Switch>
            <Route path="/" exact>
              <HomePage />
            </Route>
            {/* <Route path="/detail/:id" exact>
              <PageDetail />
            </Route> */}
            <Route path="/project/:id">
              <Project />
            </Route>
            <Route path="/staking" exact>
              <PageStaking />
            </Route>
            <Redirect to="/" />
          </Switch>

          <GlobalModal />
        </Space>
      </FullScreenStyled>
      <Footer />
    </HashRouter>
  );
};

const FullScreenStyled = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 1400px;
  padding-left: 16px;
  padding-right: 16px;

  ${({ theme }) => theme.mediaQueries.sm} {
    padding-left: 24px;
    padding-right: 24px;
  }
`;

const Space = styled.div``;
export default React.memo(App);
