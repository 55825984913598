import { InputNumber, Alert, Button, Space } from "antd";
import BigNumber from "bignumber.js";
import MyButton from "components/Button";
import CardValue from "components/CardValue";
import ConnectWalletModal from "components/Modal/ConnectWalletModal";
import CaculatorModal from "components/Modal/Caculator";
// import GlobalModalCaculator from "components/Caculator/GlobalModalCaculator";
import GlobalModal from "components/static/GlobalModal";
import tokens from "config/constants/tokens";
import { utils } from "ethers";
import { useActiveWeb3React } from "hooks";
import { useApproveCallbackCustom } from "hooks/useApprove";
import useStake from "hooks/useStaking";
import React, { useState, useRef } from "react";
import { useAppDispatch } from "state";
import {
  fetchPoolWithId,
  fetchUserStakedAmount,
  fetchUserTokenData,
  setPendingRewardToZero,
} from "state/staking";
import { useUserDataSelector } from "state/staking/selector";
import { PoolInfo, UserStakingData } from "state/types";
import styled from "styled-components";
import { getAddress, getStakingAddress } from "utils/addressHelpers";
import { addCommas, maxNumberAfterDot } from "utils/formatNumber";

const PoolCard: React.FC<{ pool: PoolInfo }> = ({ pool }) => {
  const [isApproving, setIsApproving] = useState<boolean>(false);
  const [isStaking, setIsStaking] = useState<boolean>(false);
  const [isClaiming, setIsClaiming] = useState<boolean>(false);
  const [isWithdrawing, setWithdrawing] = useState<boolean>(false);
  const [depositValue, setDepositValue] = useState<number>();
  const isAcceptStakeAfterEnd = useRef<boolean>(false);

  const { account } = useActiveWeb3React();
  const dispatch = useAppDispatch();

  const { userTokenData, userPoolData }: UserStakingData =
    useUserDataSelector();
  const { onStake, onClaim, onWithdraw } = useStake(pool.poolId);
  const [onApproveAngel] = useApproveCallbackCustom(
    getAddress(
      pool.title === "DPT" ? tokens.dpt.address : tokens.dptBnb.address
    ),
    getStakingAddress()
  );

  const isStakeAfterEnd =
    (userPoolData?.[pool?.poolId]?.firstTimeDeposit + pool.lockingTime) * 1000 <
      Date.now() && userPoolData?.[pool?.poolId]?.amountStaked !== "0";

  const handleApprove = async () => {
    setIsApproving(true);
    try {
      const tx = await onApproveAngel();
      await tx?.wait();
      dispatch(fetchUserTokenData(account));
    } catch (error) {
      console.log("error approve");
    } finally {
      setIsApproving(false);
    }
  };

  const handleStake = async () => {
    try {
      if (isStakeAfterEnd && !isAcceptStakeAfterEnd.current) {
        GlobalModal.show(
          <Alert
            message="Info"
            showIcon
            description={`Your previous staking is available for withdraw. If you staking more now, your previous fund and this time funds will be lock in next ${
              pool.lockingTime / 86400
            } days.`}
            type="info"
            action={
              <Space direction="vertical">
                <Button
                  size="middle"
                  type="primary"
                  onClick={() => {
                    isAcceptStakeAfterEnd.current = true;
                    handleStake();
                    GlobalModal.hide();
                  }}
                >
                  Accept
                </Button>

                <Button
                  size="middle"
                  danger
                  onClick={() => {
                    setDepositValue(0);
                    GlobalModal.hide();
                  }}
                >
                  Decline
                </Button>
              </Space>
            }
            closable
            onClose={GlobalModal.hide}
          />
        );
      } else {
        if (isStakeAfterEnd) isAcceptStakeAfterEnd.current = false;
        setIsStaking(true);

        const tx = await onStake(depositValue);

        const result = await tx.wait();

        dispatch(fetchPoolWithId({ account, poolId: pool.poolId }));
        dispatch(fetchUserTokenData(account));
        dispatch(fetchUserStakedAmount(account));
      }
    } catch (error: any) {
      console.log("error stake");
    } finally {
      setIsStaking(false);
    }
  };

  const handleClaim = async () => {
    try {
      setIsClaiming(true);
      const tx = await onClaim();

      const result = await tx.wait();

      dispatch(fetchUserTokenData(account));
      dispatch(setPendingRewardToZero(pool.poolId));
    } catch (error: any) {
      console.log("error claim", error);
    } finally {
      setIsClaiming(false);
    }
  };

  const handleWithdraw = async () => {
    try {
      setWithdrawing(true);
      const tx = await onWithdraw();

      const result = await tx.wait();

      dispatch(fetchUserTokenData(account));
      dispatch(setPendingRewardToZero(pool.poolId));
      dispatch(fetchPoolWithId({ account, poolId: pool.poolId }));
    } catch (error: any) {
      console.log("error withdraw", error);
    } finally {
      setWithdrawing(false);
    }
  };

  const handleMax = () => {
    console.log(
      "max",
      new BigNumber(
        pool.title === "DPT"
          ? userTokenData.dptBalance
          : userTokenData.lpBalance
      )
        .div(1e18)
        .toNumber()
    );
    setDepositValue(
      new BigNumber(
        pool.title === "DPT"
          ? userTokenData.dptBalance
          : userTokenData.lpBalance
      )
        .div(1e18)
        .toNumber()
    );
  };

  return (
    <Item key={pool.poolId}>
      <div className="content__border">
        <div className="content__icon">
          {pool.title === "DPT" ? (
            <div className="content__icon-logo">
              <img src="/logo.png" alt="logo.png" />
            </div>
          ) : (
            <div className="content__icon-logo__tokens">
              <img
                className="bnb__tokens"
                src="./images/tokens/bnb.png"
                alt="bnb.png"
              />
              <div className="content__icon-logo">
                <img src="/logo.png" alt="logo.png" />
              </div>
            </div>
          )}

          <span className="price__dpt">{pool.title}</span>
          <a
            href={
              pool.title === "DPT"
                ? "https://pancakeswap.finance/swap?outputCurrency=0xe69caef10a488d7af31da46c89154d025546e990"
                : "https://pancakeswap.finance/add/BNB/0xE69cAef10A488D7AF31Da46c89154d025546e990"
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="./images/staking/link.svg" alt="logo.png" />
          </a>
        </div>
      </div>
      <div className="content">
        <div className="content__list">
          <div className="content__list-item">
            <div className="content__list-item__left">Total Staked</div>
            <div className="content__list-item__right">
              <CardValue
                value={parseFloat(utils.formatEther(pool.totalAmount))}
                decimals={1}
              />
              <span>{pool.title}</span>
            </div>
          </div>

          <div className="content__list-item">
            <div className="content__list-item__left apr">
              APR
              <span
                className="header__button"
                onClick={() => {
                  GlobalModal.show(<CaculatorModal onClose={GlobalModal.hide} />);
                }}
              >
                {/* <img src="./images/staking/caculator.svg" alt="caculator" /> */}
              </span>
            </div>
            <div className="content__list-item__right">
              <p>
                <CardValue value={parseFloat(pool?.apy)} decimals={1} />
              </p>
              <span>%</span>
            </div>
          </div>

          <div className="content__list-item">
            <div className="content__list-item__left">Lock-up term</div>
            <div className="content__list-item__right">
              <p>{pool.lockingTime / 86400}</p> <span>days</span>
            </div>
          </div>
        </div>

        {!account ? (
          <div className="content__button" style={{ paddingBottom: "23px" }}>
            <MyButton
              Name="Connect Wallet"
              onClick={() =>
                GlobalModal.show(
                  <ConnectWalletModal onClose={GlobalModal.hide} />
                )
              }
            ></MyButton>
          </div>
        ) : (
          <>
            <div className="content__list">
              <div className="content__list-item">
                <div className="content__list-item__left">My staked</div>
                <div className="content__list-item__right">
                  <CardValue
                    value={parseFloat(
                      utils.formatEther(
                        userPoolData?.[pool?.poolId]?.amountStaked ?? "0"
                      )
                    )}
                    decimals={1}
                  />

                  <span>{pool.title}</span>
                </div>
              </div>
              <div className="content__list-item">
                <div className="content__list-item__left">Locked until </div>
                <div className="content__list-item__right">
                  <span>
                    {userPoolData?.[pool?.poolId]?.firstTimeDeposit === 0
                      ? " --/--/----"
                      : new Date(
                          userPoolData?.[pool?.poolId]?.firstTimeDeposit *
                            1000 +
                            pool.lockingTime * 1000
                        ).toLocaleDateString("en-GB")}
                  </span>
                </div>
              </div>

              {(
                pool.title === "DPT"
                  ? userTokenData.dptAllowance === "0"
                  : userTokenData.lpAllowance === "0"
              ) ? (
                <div className="content__button">
                  <MyButton
                    Name={`Approve ${pool.title}`}
                    onClick={handleApprove}
                    disabled={isApproving}
                  ></MyButton>
                </div>
              ) : (
                <>
                  <InputNumber
                    controls={false}
                    min={0}
                    addonBefore="Deposit:"
                    addonAfter={<div onClick={handleMax}>Max</div>}
                    defaultValue={depositValue}
                    value={depositValue}
                    formatter={(value) => addCommas(value)}
                    onChange={(e) => {
                      if (e > 0) setDepositValue(e);
                    }}
                  />
                  <div className="content__button">
                    <MyButton
                      Name="Stake"
                      onClick={handleStake}
                      disabled={isStaking}
                    ></MyButton>
                  </div>
                </>
              )}
            </div>

            <div className="content__list bottom">
              <div className="content__list-item">
                <div className="content__list-item__left">DPT Earned</div>
                <div className="content__list-item__right">
                  {maxNumberAfterDot(
                    utils.formatEther(
                      userPoolData?.[pool.poolId]?.pendingReward ?? "0"
                    ),
                    2
                  )}
                </div>
              </div>

              {userPoolData?.[pool?.poolId]?.firstTimeDeposit !== 0 &&
              (userPoolData?.[pool?.poolId]?.firstTimeDeposit +
                pool.lockingTime) *
                1000 <
                Date.now() &&
              userPoolData?.[pool?.poolId]?.amountStaked !== "0" ? (
                <div className="content__button">
                  <MyButton
                    Name="Withdraw All"
                    onClick={handleWithdraw}
                    disabled={isWithdrawing}
                  ></MyButton>
                </div>
              ) : (
                <div className="content__button">
                  <MyButton
                    Name="Harvest"
                    onClick={handleClaim}
                    disabled={
                      isClaiming ||
                      userPoolData?.[pool?.poolId]?.pendingReward === "0"
                    }
                  ></MyButton>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </Item>
  );
};

const Item = styled.div`
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    #2b458b;
  border-radius: 25px;
  font-size: 18px;
  font-weight: 700;
  @media (max-width: 500px) {
    font-size: 16px;
  }

  .content__border {
    color: #ffffff;
    font-size: 2.25rem;
    font-weight: 700;
    background-color: #35beff;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    display: flex;
    justify-content: left;
    align-items: center;
    padding: 15px 0px 15px 45px;
    @media (max-width: 500px) {
      font-size: 1.6rem;
    }
  }

  .content {
    margin-top: 18px;
    padding: 0 40px;

    @media (max-width: 500px) {
      padding: 0 20px;
    }

    .content__button {
      padding-top: 20px;
    }

    &__icon {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 12px;

      a {
        margin-left: 8px;
      }

      .price__dpt {
        font-size: 2.3rem;
        font-weight: 500;
        @media (max-width: 500px) {
          font-size: 1.5rem;
        }
      }

      &-logo__tokens {
        position: relative;

        .bnb__tokens {
          position: absolute;
          top: -5px;
          left: -18px;
          width: 35px;
          height: 35px;
        }
      }

      &-logo {
        width: 70px;
        height: 70px;
        background: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        padding: 0px 3px;
        margin-right: 20px;
        position: relative;

        img {
          width: 100%;
        }
      }

      span {
        color: #ffffff;
      }
    }

    &__list {
      color: #ffffff;
      padding: 25px 0 20px;
      border-bottom: 1px solid #556aa2;

      &-item {
        display: flex;
        justify-content: space-between;
        padding-bottom: 25px;
        &__left {
          color: #0da0e5;
        }

        &__left {
          &.apr {
            img {
              margin-left: 7px;
              cursor: pointer;
              transform: scale(1);
              transition: 0.5s ease-in-out;

              &:hover {
                transform: scale(1.2);
                transition: 0.5s ease-in-out;
              }
            }
          }
        }

        &__right {
          display: flex;
          justify-content: left;
          align-items: center;

          .harvest {
            display: flex;
            align-items: center;
            align-items: center;
            background: #bcb2b2;
            color: #fff9f9;
            font-size: 14px;
            font-weight: 400;
            border-radius: 37px;
            padding: 5px 10px;
            margin-left: 20px;
          }

          p {
            width: 75px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            text-align: right;

            @media (min-width: 767px) {
              width: 150px;
            }
          }

          span {
            padding-left: 7px;
          }
        }
      }

      &-item {
        &:last-child {
          padding-bottom: 0px;
        }
      }

      .ant-input-number-group-wrapper {
        width: 100%;
      }

      .ant-input-number-group {
        height: 39px;

        .ant-input-number {
          height: 39px;
          display: flex;
          align-items: center;
        }
      }

      .ant-input-number {
        &.ant-input-number-focused {
          border: unset;
          box-shadow: unset;
        }

        input {
          color: #cdcdcd;
          font-size: 1.3rem;
          text-align: right;
        }
      }

      .ant-input-number-group-addon {
        border: unset;
        color: #7a7878;
        background: #fff;
        border-radius: 37px 0px 0px 37px;
        padding: 0 24px;
        cursor: pointer;
      }

      .ant-input-number-group-addon {
        &:last-child {
          color: #18aff7;
          border-radius: 0px 37px 37px 0px;
        }
      }

      .ant-input-number {
        border: unset;
      }
    }

    &__list.bottom {
      margin-bottom: 0px;
      border-bottom: unset;

      .content__button {
        padding-top: 0px;
        .my-button {
          background: unset;
          border: solid 1px #fff;

          &:hover {
            background: rgb(24, 175, 247);
            border: unset;
            transform: scale(1);
          }
        }
      }
    }
  }
`;

export default PoolCard;
