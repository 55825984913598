import React from "react";
import styled from "styled-components";
import { PoolInfo, UserPoolData } from "state/types";
import PoolCard from "./PoolCard";

const Staking: React.FC<{ stakingList: PoolInfo[] }> = ({ stakingList }) => {
  return (
    <StakingStyled>
      <div className="staking__styled">
        {stakingList.map((pool, index) => (
          <PoolCard pool={pool} />
        ))}
      </div>
    </StakingStyled>
  );
};

const StakingStyled = styled.div`
  .staking__styled {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    @media (max-width: 1200px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: 500px) {
      grid-template-columns: repeat(1, 1fr);
    }

    padding: 0px 0px 30px 0px;
  }
`;

export default Staking;
