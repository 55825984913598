import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
export interface ICountDown {
  date: string | Date | number;
}

const Countdown: React.FC<ICountDown> = ({ date }) => {
  const INTERVAL = 1000;
  const intervalRef = useRef(null);
  const elRef = useRef(null);
  const utcDate = typeof date === "number" ? date : new Date(date).getTime();
  const currentDate = Date.now();
  const diffTime = utcDate - currentDate;
  const durationRef = useRef(
    moment.duration(diffTime <= 0 ? 1 : diffTime, "milliseconds")
  );

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      const eventDate =
        typeof date === "number" ? date : new Date(date).getTime();
      const currDate = Date.now();
      const diff = eventDate - currDate;
      if (diff < 0) {
        clearInterval(intervalRef.current);
      }
      const duration = moment.duration(
        (durationRef.current as any) - INTERVAL,
        "milliseconds"
      );
      if (elRef.current) {
        elRef.current.innerText = `${duration.days()}d ${duration.hours()}h ${duration.minutes()}m ${duration.seconds()}s`;
      }
      durationRef.current = duration;
    }, INTERVAL);
    return () => clearInterval(intervalRef.current);
  }, [date]);

  return <div ref={elRef}></div>;
};

export default Countdown;
