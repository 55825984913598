import styled from 'styled-components'
import { connectorLocalStorageKey, ConnectorNames } from '@diviner-protocol/uikit'
import { connectorsMarket } from 'config/constants/walletsMarket'
import useAuth from 'hooks/useAuth'

const ConnectWalletModal = ({ onClose }) => {
  const { login } = useAuth()

  const handleLogin = (connectorId: ConnectorNames) => {
    login(connectorId)
    window.localStorage.setItem(connectorLocalStorageKey, connectorId)
    onClose()
  }

  return (
    <BlockCaculator>
      <div className="connect-wallet">
        <p>ROI Calculator</p>
        <p className="close" onClick={onClose}>
          X
        </p>
      </div>

      <div className="form">
        <span className="title">DPT STAKED</span>

        <div className="dpt__staked__form">
          <div className="dpt__staked__form-item">
            <div className="box__input">
              <input type="text" id="dpt" name="dpt" placeholder="0.00 DPT"/>
            </div>
          </div>

          <div className="dpt__staked__form-item dpt__staked__form-item--custome-font">
            <div className="box__input">
              <input type="text" id="usd" name="usd" placeholder="0.00 usd"/>
            </div>
          </div>

          <span className="icon">
            <img src="./images/icon-transfer.png" alt=""/>
          </span>
        </div>

        <div className="dpt__staked__days">
          <div className="dpt__staked__days-item active">
            30 days
          </div>

          <div className="dpt__staked__days-item">
            90 days
          </div>

          <div className="dpt__staked__days-item">
            180 days
          </div>
        </div>

        <img className="icon-arrow-down" src="./images/icon-arrow-down.png" alt="icon arrow down"/>

        <div className="dpt__staked__estimate">
          <h3 className="dpt__staked__estimate-title">
            estimate roi
          </h3>

          <div className="dpt__staked__estimate-coin-number">
            <img src="./images/icon-diviner.png" alt="token dpt"/>
            <span>1.000.000</span>
          </div>

          <div className="dpt__staked__estimate-approximately">
            <span>~</span><h4>9.388.736</h4><span className="usd">USD</span>
          </div>
        </div>
      </div>
    </BlockCaculator>
  )
}

const BlockCaculator = styled.div`
  height: 100%;
  width: 100%;
  background: #fff;
  border-radius: 25px;
  font-weight: bold;
  color: #333333;
  text-align: center;

  @media (max-width: 767px) {
    max-width: 360px;
    transform: scale(0.9);
    margin-top: 150px;
  }

  .connect-wallet {
    background: #55b3f8;
    border-radius: 12px 12px 0px 0px;
    height: 74px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    p {
      margin: 0;
      color: #fff;
      font-weight: 600;
      font-size: 	2rem;
    }

    .close {
      position: absolute;
      top: 50%;
      right: 33px;
      transform: translate(-50%, -50%);
      cursor: pointer;

      @media (max-width: 767px) {
        top: 30%;
        right: 13px;
      }
    }
  }

  .form {
    background: #22376F;
    grid-template-columns: repeat(2, 2fr);
    justify-items: center;
    padding: 40px 40px;
    color: #ffffff;

    .icon-arrow-down {
      max-width: 40px;
      margin-bottom: 36px;
    }

    span {
      &.title {
        color: #ffffff;
        display: block;
        width: 100%;
        text-align: left;
        font-size: 1.375rem;
        font-weight: 600;
        margin-bottom: 15px;
      }
    }

    .dpt__staked {
      &__form {
        width: 608px;
        background: #FFFFFF;
        border: 2px solid #C9C9C9;
        box-shadow: inset 0px -4px 12px rgba(90, 90, 90, 0.2);
        border-radius: 12px;
        padding: 20px 56px;
        position: relative;
        margin-bottom: 28px;

        @media (max-width: 767px) {
          width: auto;
          padding: 20px;
        }

        .icon {
          position: absolute;
          top: 50%;
          right: 20px;
          transform: translateY(-50%);
          cursor: pointer;
          opacity: 1;
          transition: .5s ease-in-out;

          &:hover {
            opacity: 0.7;
            transition: .5s ease-in-out;
          }

          img {
            width: 20px;
            transform: rotate(90deg);
          }
        }

        &-item {
          margin-bottom: 32px;

          .box__input {
            text-align: right;

            input {
              border: none;
              outline: none;
              color: #858585;
              text-align: right;
              font-size: 1.375rem;
              font-weight: 600;
              width: 100%;
            }
          }
        }

        &-item--custome-font {
          margin-bottom: 0;

          .box__input {
            input {
              font-size: 1.125rem;
            }
          }
        }
      }

      &__days {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 24px;
        margin-bottom: 36px;

        @media (max-width: 767px) {
          gap: 8px;
        }

        &-item {
          background-color: #E8E8E8;
          border-radius: 36px;
          padding: 15px 0;
          text-align: center;
          color: #000000;
          font-size: 1.125rem;

          &.active {
            background-color: #51A5F3;
            color: #ffffff;
          }
        }
      }

      &__estimate {
        background: #10A7F3;
        border-radius: 12px;
        padding: 30px;
        text-align: left;

        &-title {
          color: #ffffff;
          font-size: 1.375rem;
          line-height: 26px;
          font-weight: 600;
          text-transform: uppercase;
          margin-bottom: 28px;
        }

        &-coin-number {
          display: flex;
          align-items: center;
          gap: 12px;
          margin-bottom: 28px;

          img {
            max-width: 40px;
          }

          span {
            font-weight: 600;
            font-size: 2.375rem;
            line-height: 46px;
          }
        }

        &-approximately {
          font-weight: 300;
          font-size: 1.25rem;
          line-height: 24px;
          display: flex;
          align-items: center;

          h4 {
            padding-left: 5px;
            color: #ffffff;
          }

          span {
            padding-top: 9px;

            &.usd {
              padding-top: 0;
              padding-left: 5px;
            }
          }
        }
      }
    }
  }
`
export default ConnectWalletModal
