import { createAsyncThunk } from "@reduxjs/toolkit";
import { ethers } from "ethers";
import {
  getLaunchpadContract,
  getLaunchpadV2Contract,
} from "utils/contractHelpers";
import erc20ABI from "config/abi/erc20.json";
import tokens from "config/constants/tokens";
import {
  getAddress,
  getLaunchpadAddress,
  getLaunchpadV2Address,
  getStakingAddress,
} from "utils/addressHelpers";
import multicall from "utils/multicall";
import { roundUsing } from "utils/helpers";
import { QUERY_USER_HISTORY } from "gql/history";
import { client } from "Providers";
import { ITEMS_PER_PAGE, LAUNCHPAD_ACTION } from "./constants";

const {
  GET_POOL,
  GET_POOL_USER,
  BUY_TRIBES_USER,
  GET_USER_LAUNCHPAD_TOKEN_INFO,
  GET_USER_CLAIM_AMOUNT,
  GET_USER_HISTORY,
} = LAUNCHPAD_ACTION;
export const getPool = createAsyncThunk(GET_POOL, async (pool) => {
  const contract = getLaunchpadContract();
  const response = await contract.pools(0);
  const keys = ["totalAmount", "releaseRate"];
  return {
    pool: {
      ...keys.reduce(
        (accmu, key) => ({ ...accmu, [key]: response?.[key].toString() }),
        {}
      ),
    },
  };
});

export const getPoolUser = createAsyncThunk(
  GET_POOL_USER,
  async (data: any) => {
    try {
      const contract = data?.isPublic
        ? getLaunchpadV2Contract()
        : getLaunchpadContract();
      const [pool, user, amount, claimToken] = await Promise.all([
        contract.pools(data.pool),
        contract.users(data.pool, data.account),
        data?.isPublic
          ? contract.getAmountUserCanBuy(data?.pool)
          : contract.getAmountUserCanBuy(data?.pool, data?.account),
        contract.getClaimToken(data?.pool, data?.account),
      ]);
      const poolKeys = [
        "releaseRate",
        "delayTime",
        "lockingTime",
        "refundTime",
        "endTime",
        "startTime",
        "buyRate",
      ];
      const userKeys = ["claimedMark"];
      const formattedAmount = roundUsing(
        Math.floor,
        +ethers.utils.formatEther(amount),
        2
      );
      const userAmount = roundUsing(
        Math.floor,
        // eslint-disable-next-line
        +ethers.utils.formatEther(user?.["amount"]),
        2
      );
      const updatedFormattedAmount = formattedAmount;
      const maxAmountUserCanBuy =
        // eslint-disable-next-line
        updatedFormattedAmount - userAmount < 0
          ? 0
          : // : roundUsing(Math.floor, updatedFormattedAmount - userAmount, 2);
            updatedFormattedAmount - userAmount;
      const releaseAmount = +ethers.utils.formatEther(pool?.releaseAmount);
      const totalAmount = +ethers.utils.formatEther(pool?.totalAmount);
      const remainToken = releaseAmount - totalAmount;
      const updatedMaxAmountUserCanBuy =
        remainToken < maxAmountUserCanBuy ? remainToken : maxAmountUserCanBuy;

      return {
        project: {
          ...poolKeys.reduce(
            (accmu, key) => ({
              ...accmu,
              [key]: pool?.[key]?.toString(),
            }),
            {
              totalAmount,
              totalWhitelistAmount: +ethers.utils.formatEther(
                pool?.totalWhitelistAmount || 0
              ),
              releaseAmount,
              minStakedAmount: +ethers.utils.formatEther(
                pool?.minStakedAmount || 0
              ),
              claimPercent: pool?.claimPercent,
            }
          ),
        },
        user: {
          ...userKeys.reduce(
            (accmu, key) => ({
              ...accmu,
              [key]: user?.[key].toString(),
            }),
            {
              maxAmountUserCanBuy: roundUsing(
                Math.floor,
                updatedMaxAmountUserCanBuy < 0 ? 0 : updatedMaxAmountUserCanBuy,
                2
              ),
              amountUserCanBuy: updatedFormattedAmount,
              amount: userAmount,
              claimToken: +ethers.utils.formatEther(claimToken),
              whitelistAmount: +ethers.utils.formatEther(
                user?.whitelistAmount || 0
              ),
              stakingTicket: +ethers.utils.formatEther(
                user?.stakingTicket || 0
              ),
            }
          ),
        },
      };
    } catch (error: any) {
      console.dir(error);
    }
  }
);
export const fetchUserLaunchpadTokenInfo = createAsyncThunk(
  GET_USER_LAUNCHPAD_TOKEN_INFO,
  async (data: { account: string; isPublic: boolean }) => {
    const calls = [];
    const busdToken = getAddress(tokens.busd.address);
    const address = data?.isPublic
      ? getLaunchpadV2Address()
      : getLaunchpadAddress();
    calls.push({
      address: busdToken,
      name: "balanceOf",
      params: [data?.account],
    });
    calls.push({
      address: busdToken,
      name: "allowance",
      params: [data?.account, address],
    });
    try {
      const [busdBalance, busdAllowance] = await multicall(erc20ABI, calls, {
        requireSuccess: false,
      });

      return {
        userTokenData: {
          busdBalance: busdBalance.toString(),
          busdAllowance: busdAllowance.toString(),
        },
      };
    } catch (error) {
      console.log("error fetchUserLaunchpadTokenInfo", error);
      return {
        userTokenData: {
          busdAllowance: "0",
          busdBalance: "0",
        },
      };
    }
  }
);

export const fetchHistory = createAsyncThunk(
  GET_USER_HISTORY,
  async (data: { account: string; poolId: string }) => {
    try {
      const response = await client.query({
        query: QUERY_USER_HISTORY,
        variables: {
          id: data?.account?.toLowerCase(),
          pid: data?.poolId?.toString(),
        },
      });
      const [user] = response?.data?.users;

      const totalPage = Math.ceil(
        (user?.history?.length || 0) / ITEMS_PER_PAGE
      );
      return {
        history: {
          transactions: user?.history || [],
          pagination: {
            currentPage: 0,
            totalPage,
          },
        },
      };
    } catch (error) {
      console.log("error", error);
    }
  }
);
// const fetchUserClaimAmount = createAsyncThunk(GET_USER_CLAIM_AMOUNT,(data)=>{
//   try {

//   } catch (error) {

//   }
// });
// export const buyTribes = createAsyncThunk(
//   BUY_TRIBES_USER,
//   async (data: any) => {
//     try {
//       const lib = getLibrary();
//       const contract = getLaunchpadContract(library.getSigner(data?.account));
//       const response = await contract.buy(data?.pool, data?.amount);
//       console.log("response", response);
//     } catch (error) {
//       console.log("error", error);
//     }
//   }
// );
