import { Tooltip } from "antd";
import React from "react";
import { usePoolsSelector, useUserDataSelector } from "state/staking/selector";
import styled from "styled-components";
import { numberWithCommas } from "utils/helpers";

const TrackedAmount: React.FC = () => {
  const userData = useUserDataSelector();
  const pools = usePoolsSelector();
  const totalTicketInEther = pools.reduce((accmu, item) => {
    // eslint-disable-next-line
    return (accmu += parseFloat(item?.totalAmount) * item?.multiplyForTicket);
  }, 0);
  const totalTicket = Math.round(totalTicketInEther / 10 ** 18);
  const myAllocationAmount =
    userData?.trackedAmount >= 50000
      ? Number(((userData?.trackedAmount / totalTicket) * 100).toFixed(2))
      : 0;
  return (
    <TrackedAmountStyle>
      <TrackedItemStyle>
        <h3>My Ticket</h3>
        <p>{numberWithCommas(userData?.trackedAmount)}</p>
      </TrackedItemStyle>
      <TrackedItemStyle>
        <h3>Total Ticket</h3>
        <p>{numberWithCommas(totalTicket || 0)}</p>
      </TrackedItemStyle>
      <TrackedItemStyle>
        <div className="tracked__styled__title">
          <h3>Estimate Allocation</h3>
          <Tooltip
            style={{ fontFamily: "Chalkboard SE", fontSize: "18px" }}
            title="Get minimum 50,000 Tickets for guaranteed allocation!"
          >
            <img src="./images/staking/apr.svg" alt="" />
          </Tooltip>
        </div>

        <p>{myAllocationAmount || 0}%</p>
      </TrackedItemStyle>
    </TrackedAmountStyle>
  );
};
const TrackedAmountStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  margin: 0 auto;
  gap: 32px;
  flex-wrap: wrap;
  margin-bottom: 48px;
  @media (max-width: 1200px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 500px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const TrackedItemStyle = styled.div`
  color: #fff;
  text-align: center;
  background-color: #2b84cd;
  border-radius: 14px;
  flex: 1;
  padding: 10px 0px;
  h3 {
    color: #9afff9;
    font-size: 1.3rem;
  }
  p {
    font-size: 26px;
    font-weight: 800;
  }
  .tracked__styled__title {
    position: relative;
    img {
      position: absolute;
      right: 10%;
      top: 55%;
      transform: translate(-50%, -50%);
      cursor: pointer;
    }
  }
`;

export default TrackedAmount;
